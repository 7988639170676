import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles, makeStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from "@material-ui/core/IconButton";
import HelpOutline from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";

// TODO: Overflow is not being shown on the information tables (they don't scroll)
// TODO: Put image source in Import to make it load faster like in AD_ShowCase
const StyledTableCell = withStyles(theme => ({
    head: {
        color: "#FFFFFF",
        backgroundColor: "#2180CE",
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

class AD_RegistrationCard extends Component {

    state = {
        displayInfo: false
    };

     createData(feature, availability) {
        return {feature, availability };
    }

    render() {

        const isMobile = window.matchMedia("(max-width: 1025px)").matches;
        const cardContainerCSS = isMobile ? registrationCardContainerStyleMobile : registrationCardContainerStyle;
        const buttonCSS = this.props.card.price !== "(Coming Soon)" ? joinButtonStyle:joinButtonDisabledStyle;

        return (
            <div style={cardContainerCSS}>

                {this.state.displayInfo &&
                    <Paper style={informationOverlayStyle} id={"informationOverlay"}>
                        <IconButton style={{float:"right"}} onClick={()=> this.setState({displayInfo: false})}>
                            <CloseIcon/>
                        </IconButton>

                        <Table stickyHeader={true} aria-label="simple table" style={{overflow: "auto"}}>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Feature</StyledTableCell>
                                    <StyledTableCell align="center">Access</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.card.informationTableRows.map(row => (
                                    <TableRow key={row.feature}>
                                        <TableCell component="th" scope="row">
                                            {row.feature}
                                        </TableCell>
                                        <TableCell align="center">{row.availability}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                }

                <div style={headerStyle}>
                    <div></div>
                    <h2 style={h2Style}>{this.props.card.title}</h2>
                    <IconButton onClick={()=> this.setState({displayInfo: true})}>
                        <HelpOutline/>
                    </IconButton>
                </div>
                <img style={imageStyle} src={this.props.card.image} alt=""/>
                <p style={pStyle}>Plans start at:</p>
                <h2 style={h2Style}>{this.props.card.price}</h2>


                <MuiThemeProvider theme={theme}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size={"large"}
                        style={buttonCSS}
                        onClick={() => {
                            this.props.card.onClick();
                            this.props.planSelected(this.props.card.planType);
                        }}
                    >
                        {this.props.card.buttonText}
                    </Button>
                </MuiThemeProvider>

            </div>
        );
    }
}
const informationOverlayStyle = {
    position: "absolute",
    height: "90%",
    width: "90%",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    margin: "auto",
    zIndex: "10",
    overflow: "auto"
};

const registrationCardContainerStyle = {
    position: "relative",
    display: 'grid',
    textAlign: 'center',
    alignContent: 'center',
    gridTemplateRows: '1fr 3fr 1fr 1fr 1fr',
    width: '25vw',
    height: '60vh',
    maxWidth: "420px",
    maxHeight: "",
    padding: '2rem',
    margin: "auto",
    borderBottom: "solid 8px #2180CE",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 10px 2px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
};

const registrationCardContainerStyleMobile = {
    position: "relative",
    display: 'grid',
    textAlign: 'center',
    alignContent: 'center',
    gridTemplateRows: '1fr 3fr 1fr 1fr 1fr',
    width: '90%',
    maxWidth: "340px",
    maxHeight: '400px',
    padding: '2rem',
    borderBottom: "solid 8px #2180CE",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 10px 2px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
};

const headerStyle = {
    display: "grid",
    gridTemplateColumns: "1fr 3fr 1fr",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
};

const h2Style = {
    width: "100%",
    color: '#2180CE',
    textAlign: "center",
    fontSize: 'calc(20px + (24 - 20) * ((100vw - 300px) / (1600 - 300)))',
    margin: "0"
};

const imageStyle = {
    height: 'auto',
    width: '90%',
    justifySelf: 'center',
    alignSelf: 'center'
};

const joinButtonStyle = {
    outline:"none",
    margin: 'auto',
    textTransform: "none",
    width: "80%",
    fontSize: 'calc(18px + (26 - 18) * ((100vw - 300px) / (1600 - 300)))',
};

const joinButtonDisabledStyle = {
    textTransform: "none",
    textAlign: "center",
    height: '8vh',
    maxHeight: '50px',
    width: '80%',
    background: '#cccccc',
    color: '#FFFFFF',
    fontSize: 'calc(18px + (26 - 18) * ((100vw - 300px) / (1600 - 300)))',
    margin: 'auto',
    pointerEvents: "none",
    outline:"none"
};

const pStyle = {
    color: '#2180CE',
    fontSize: 'calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300)))',
    margin: 'auto',
    alignSelf: 'end'
};

const theme = createMuiTheme({
    palette: {
        secondary: {
            main: '#2180CE'
        }
    },
});

// PropTypes
AD_RegistrationCard.propTypes = {
    card: PropTypes.object.isRequired,
    planSelected: PropTypes.func.isRequired
};


export default AD_RegistrationCard;