import React, {Component} from "react";
import { css } from '@emotion/react';
import {Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {PulseLoader} from "react-spinners";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";


// TODO: [P1] Error checking for bad login
// TODO: See if storing password in state while user is typing is safe
// TODO: Forgot Password currently doesn't do anything
class Login extends Component {

    componentDidMount() {

        // Initial media match
        this.setState({isMobile: window.matchMedia("(max-width: 850px)").matches});

        window.onresize = () => {
            // Subsequent  media matches on resize
            this.setState({isMobile: window.matchMedia("(max-width: 850px)").matches})
        }
    }

    state = {
        username: "",
        password: "",

        isProcessingRequest: false,

        usernameError: false,
        usernameHelperText: '',

        passwordError: false,
        passwordHelperText: '',
    };

    authenticate = async (e) => {

        this.setState({isProcessingRequest: true});

        let finalBasic = this.state.username + ":" + this.state.password;

        // Reset password state so that it's not saved there for security. TODO: Come back to this
        this.setState({password: ""});
        let response = await fetch('https://cprforms.com/api/v1/authenticate', {
            method: "POST",
            headers: {
                "Authorization": "Basic " + btoa(finalBasic)
            },

            body: JSON.stringify({
                deviceID: "23423"
            })
        });

        if (response.ok){
            let user = this.state.username;
            this.setState({username: "", password: ""});

            // this.props.toggleLoadingScreen();
            let respObject = await response.json();

            // Changes the page to dashboard while also passing the state prop with the needed information
            this.props.history.push({
                pathname: '/dashboard',
                state: {
                    authToken: respObject.token,
                    username: user,
                    userStatus: parseInt(respObject.userStatus)

                }
            })

        } else if (response.status === 401){
            this.setState({
                usernameError: true,
                passwordError: true,
                passwordHelperText: "Wrong Username/Password",
                isProcessingRequest: false
            })
        }

        else if (response.status === 404) {
            this.setState({
                usernameError: true,
                passwordError: true,
                passwordHelperText: "Wrong Username/Password",
                isProcessingRequest: false
            })
        }

        else {
            alert("There appears to be a connection error, please try to login again later.")
            this.setState({isProcessingRequest: false});
        }
    };

    handleKeydown = (event) => {
        if (event.key === "Enter"){
            this.authenticate().then(function () {

            })
        }
    }

    render() {

        const loginCardCSS =  this.state.isMobile ?  loginCardStyleMobile : loginCardStyle;

        return (
            <div style={loginContainerCSS}>
                <div style={loginCardCSS}>

                    <MuiThemeProvider theme={theme}>
                        <div style={loginTypeSelectorCSS}>
                            <span style={{float: "left", color: "#2180CE", cursor: "pointer", borderBottom: "solid 1px #2180CE"}}>Instructor</span>
                            <span style={{float: "right", color: "#2180CE", cursor: "pointer"}}>Admin</span>
                        </div>
                        <Link to={"/"}>
                            <img src="assets/ToolKit_Compact.svg" alt="Toolkit Compact Logo" style={loginIllustrationCSS}/>
                        </Link>

                        <TextField style={loginInputContainerCSS}
                                   error={this.state.usernameError}
                                   helperText={this.state.usernameHelperText}
                                   label="Username"
                                   onChange={evt => this.setState({username: evt.target.value})}
                                   onFocus={() => this.setState({usernameError: false, usernameHelperText: ''})}
                                   onKeyDown={this.handleKeydown}
                        />


                        <TextField style={loginInputContainerCSS}
                                   error={this.state.passwordError}
                                   helperText={this.state.passwordHelperText}
                                   label="Password"
                                   type={"password"}
                                   onChange={evt => this.setState({password: evt.target.value})}
                                   onFocus={() => this.setState({passwordError: false, passwordHelperText: ''})}
                                   onKeyDown={this.handleKeydown}
                        />


                        <Button
                            disabled={this.state.isProcessingRequest}
                            variant="contained"
                            color="primary"
                            size="large"
                            style={loginButtonCSS}
                            onClick={this.authenticate}
                        >
                            Login
                            <PulseLoader
                                css={overrideLoading}
                                sizeUnit={"px"}
                                size={this.state.isMobile ? 9 : 12}
                                color={'#FFFFFF'}
                                loading={this.state.isProcessingRequest}
                            />
                        </Button>
                    </MuiThemeProvider>
                    <Link to={"/recovery"}>
                        <span style={{textAlign: "center", color: "#2180CE", fontSize: "12px", marginTop: "10px", cursor: "pointer"}}>Forgot Password?</span>
                    </Link>

                </div>
            </div>
        );
    }
}

const theme = createMuiTheme({
    palette: {
        primary: { 500: "#2180CE" },
    },
    typography: { useNextVariants: true },
});


let loginContainerCSS = {
    display: "flex",
    height: '100vh',
    alignItems: "center",
    justifyContent: "center"
};

let loginCardStyle = {
    width: "40%",
    height: "auto",
    maxWidth: "450px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "20px",
    borderBottom: "solid 5px #2180CE",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 13px 4px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
};

let loginCardStyleMobile = {
    width: "100%",
    height: "auto",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "20px",
};

let loginTypeSelectorCSS = {
    width: "80%",
};

let loginIllustrationCSS = {
    height: "200px",
    width: "100%",
    padding: "10%"
};

let loginInputContainerCSS = {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    marginBottom: "30px"
};

let loginButtonCSS = {
    height: "auto",
    width: "80%",
    border: "none",
    color: "#FFFFFF",
    backgroundColor: "#2180CE",
    borderRadius: "5px",
    textTransform: "none"
};

const overrideLoading = css`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2180CE;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

export default Login;
