import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import {css} from "@emotion/react";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import {PulseLoader} from "react-spinners";


// If I ever want to put this component  on GitHub:
// {Array.from(Array(6)).map((x, index) => <input key={index} maxLength={1} type="text" style={charRectangleStyle} onKeyUp={event => this.changeFocus(event)}/>)}
// TODO: Hook up to API
// TODO: Copy/Paste functionality
class AD_VerificationCode extends Component {

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    verifyCode = async () => {

        this.setState({isProcessingRequest: true});

        let code = this.state.codeOne + this.state.codeTwo + this.state.codeThree + this.state.codeFour + this.state.codeFive + this.state.codeSix;
        await fetch('https://cprforms.com/api/v1/confirm', {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                "username": this.props.username,
                "token": code
            })

        }).then(response => {

            if (response.ok) {
                this.setState({codeOne: "", codeTwo: "", codeThree: "", codeFour: "", codeFive: "", codeSix: ""});
                this.props.changePageFunc();

            } else if (response.status === 400) {
                this.setState({incorrectCode: true});

            } else {
                alert("There appears to be a connection error. Please try again later.")
            }
        });

        this.setState({isProcessingRequest: false});

    };

    state = {
        isProcessingRequest: false,
        incorrectCode: false,

        codeOne: "",
        codeTwo: "",
        codeThree: "",
        codeFour: "",
        codeFive: "",
        codeSix: ""
    };

    changeFocus = (e) => {

        let target = e.target;

        // Go to next input
        if (target.nextElementSibling !== null && target.value.length === target.maxLength){
            target.nextElementSibling.focus();
        } else if (target.parentElement.nextElementSibling !== null && target.parentElement.nextElementSibling.firstElementChild.nodeName === "INPUT" && target.value.length === target.maxLength){
            target.parentElement.nextElementSibling.firstElementChild.focus();
        }
    };

    clearCode = () => {
        this.setState({incorrectCode: false, codeOne: "", codeTwo: "", codeThree: "", codeFour: "", codeFive: "", codeSix: ""});
        this.formRef.current.reset();
    };

    resetErrorColor = () => {
        if (this.state.incorrectCode){
            this.setState({incorrectCode: false});
        }
    };

    render() {

        let isMobile = window.matchMedia("(max-width: 850px)").matches;
        let mainContainerCSS = isMobile ? mainContainerStyleMobile : mainContainerStyle;
        let inputContainerCSS = isMobile ? inputContainerStyleMobile : inputContainerStyle;
        let charRectangleCSS = isMobile ? charRectangleStyleMobile : charRectangleStyle;

        if (this.state.incorrectCode){
            charRectangleCSS = Object.assign({},charRectangleCSS, {borderBottom: "6px solid #CE4321"})
        }

        return (
            <div id={"verification-code-container"} style={mainContainerCSS}>

                <form id={"inputContainer"} style={inputContainerCSS} ref={this.formRef}>

                    <div style={rowContainerStyle}>
                        <input autoComplete={"asxcasx"} maxLength={1} type="text" style={charRectangleCSS}
                               onInput={event => this.changeFocus(event)} onFocus={this.resetErrorColor}
                               onChange={event => this.setState({codeOne: event.target.value})} />

                        <input autoComplete={"asxcasx"} maxLength={1} type="text" style={charRectangleCSS}
                               onInput={event => this.changeFocus(event)} onFocus={this.resetErrorColor}
                               onChange={event => this.setState({codeTwo: event.target.value})}/>

                        <input autoComplete={"asxcasx"} maxLength={1} type="text" style={charRectangleCSS}
                               onInput={event => this.changeFocus(event)} onFocus={this.resetErrorColor}
                               onChange={event => this.setState({codeThree: event.target.value})}/>
                    </div>

                    <div style={rowContainerStyle}>
                        <input autoComplete={"asxcasx"} maxLength={1} type="text" style={charRectangleCSS}
                               onInput={event => this.changeFocus(event)} onFocus={this.resetErrorColor}
                               onChange={event => this.setState({codeFour: event.target.value})}/>

                        <input autoComplete={"asxcasx"} maxLength={1} type="text" style={charRectangleCSS}
                               onInput={event => this.changeFocus(event)} onFocus={this.resetErrorColor}
                               onChange={event => this.setState({codeFive: event.target.value})}/>

                        <input autoComplete={"asxcasx"} maxLength={1} type="text" style={charRectangleCSS}
                               onInput={event => this.changeFocus(event)} onFocus={this.resetErrorColor}
                               onChange={event => this.setState({codeSix: event.target.value})}/>
                    </div>
                </form>
                {this.state.incorrectCode && <span style={{fontSize: "12px", width: '65%', marginTop: "2%", textAlign: "center", color: "#CE4321"}}>Invalid Code</span>}

                <span style={{fontSize: "18px", width: '65%', marginTop: "10%", textAlign: "center"}}>Please enter the 6 digit code sent to your email</span>

                <MuiThemeProvider theme={theme}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        style={joinButtonStyle}
                        onClick={this.clearCode}
                    >
                        Clear
                    </Button>

                    <Button
                        disabled={this.state.isProcessingRequest}
                        variant="contained"
                        color="primary"
                        size="large"
                        style={joinButtonStyle}
                        onClick={this.verifyCode}
                    >
                        Verify
                        <PulseLoader
                            css={overrideLoading}
                            sizeUnit={"px"}
                            size={isMobile ? 9 : 12}
                            color={'#FFFFFF'}
                            loading={this.state.isProcessingRequest}
                        />
                    </Button>

                </MuiThemeProvider>
            </div>
        );
    }
}

const theme = createMuiTheme({
    palette: {
        primary: { 500: "#2180CE" },
    },
    typography: { useNextVariants: true },
});

const mainContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5%"
};

const mainContainerStyleMobile = {
    height: "70vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start"
};

const inputContainerStyle = {
    display: "grid",
    gridTemplateColumns: "auto auto",
    columnGap: "20px",
    alignItems: "center",
    justifyContent: "center"
};

const inputContainerStyleMobile = {
    display: "grid",
    gridTemplateColumns: "auto",
    rowGap: "20px",
    alignItems: "start",
    alignContent: "start",
    justifyItems: "center"
};

const rowContainerStyle = {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    columnGap: "20px",
    alignItems: "center",
    justifyContent: "center"
};

let charRectangleStyle = {
    height: "110px",
    width: "90px",
    fontSize: "40px",
    textAlign: "center",
    textTransform: "uppercase",
    borderRadius: "5px",
    backgroundColor: "#F0EFEF",
    border: "none",
    borderBottom: "6px solid #2180CE"
};

let charRectangleStyleMobile = {
    height: "73px",
    width: "60px",
    fontSize: "30px",
    textAlign: "center",
    textTransform: "uppercase",
    borderRadius: "5px",
    backgroundColor: "#F0EFEF",
    border: "none",
    borderBottom: "6px solid #2180CE"
};

const joinButtonStyle = {
    gridArea: "button",
    maxWidth: "300px",
    maxHeight: '50px',
    width: '55%',
    textAlign: 'center',

    marginTop: '1rem',
    fontSize: '18px',
    textTransform: "none"
};

const overrideLoading = css`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2180CE;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

AD_VerificationCode.propTypes = {
    changePageFunc: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired
};

export default AD_VerificationCode;