import React, {Component} from 'react';
import PropTypes from 'prop-types'
import TextField from "@material-ui/core/TextField";
import {createMuiTheme, MuiThemeProvider, withStyles} from "@material-ui/core";
import HelpOutline from '@material-ui/icons/HelpOutline';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import {BeatLoader, PulseLoader, SyncLoader} from "react-spinners";
import {css} from "@emotion/react";
import Paper from "@material-ui/core/Paper";

const unitedStates = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ];


// TODO: Page is much taller than it needs to be on mobile
// TODO: Align "Training Center" and Instructor vertically when not in mobile view
class AD_RegistrationInformationCard extends Component {

    state = {
        trainingCenterName: '',
        trainingCenterID: '',
        instructorFirst: '',
        instructorLast: '',
        instructorEmail: '',
        instructorUnitedState: '',
        instructorCity: '',
        instructorUsername: '',
        instructorPassword: '',
        instructorPasswordCheck: '',

        firstNameError: false,
        firstNameHelperText: '',

        lastNameError: false,
        lastNameHelperText: '',

        stateError: false,

        cityError: false,
        cityHelperText: '',

        emailError: false,
        emailHelperText: '',

        usernameError: false,
        usernameHelperText: '',

        passwordError: false,
        passwordHelperText: '',

        passwordMatchError: false,
        passwordMatchHelperText: '',

        isProcessingRequest: false
    };

    sendInformation = async () => {

        this.setState({isProcessingRequest: true});

        this.props.updateUserInfo({
            username: this.state.instructorUsername,
            email: this.state.instructorEmail
        });

        // Check all fields are filled, else exit process of sendInformation
        if (!this.simpleInformationCheck()) {
            this.setState({isProcessingRequest: false});
            return;
        }

        // Final check on passwords matching, else exit process of sendInformation
        if (!this.doPasswordsMatch()) {
            this.setState({isProcessingRequest: false});
            return;
        }

        try {

            await fetch('https://cprforms.com/api/v1/users', {
                method: 'POST',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    'trainingCenterName': this.state.trainingCenterName,
                    'trainingCenterID': this.state.trainingCenterID,
                    'instructorFirst': this.state.instructorFirst,
                    'instructorLast': this.state.instructorLast,
                    'instructorEmail': this.state.instructorEmail,
                    'instructorUsername': this.state.instructorUsername,
                    'instructorPassword': this.state.instructorPassword,
                    'instructorPasswordCheck': this.state.instructorPasswordCheck,
                    "instructorState": this.state.instructorUnitedState,
                    "instructorCity": this.state.instructorCity,
                }),
            }).then(response => {

                if (response.ok) {
                    response.text().then(response => {
                        this.props.changePageFunc();
                    });
                }

                // Handles duplicate email or username
                else if (response.status === 409){

                    response.json().then(resp => {
                        if (parseInt(resp.message) === 0){
                            this.setState({emailError: true, emailHelperText: "Email already in use",});
                        } else if (parseInt(resp.message) === 1) {
                            this.setState({usernameError: true, usernameHelperText: "Username already in use",});
                        } else {
                            alert("An error has occurred, please try again later.")
                        }
                    });
                }
            });

        } catch (e) {
            alert("There appears to be a connection issue. Please check your internet or try again later.");
        }

        this.setState({isProcessingRequest: false});
    };

    // Check that email input is filled and validate  via regex
    isEmailValid() {

        if (this.state.instructorEmail.length < 1){
            this.setState({emailError: true, emailHelperText: "required"});
            return false;
        }

        if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.instructorEmail)) {
            this.setState({emailError: true, emailHelperText: "Invalid email address"});
            return false;
        }

        return true;
    }

    doPasswordsMatch() {
        if (this.state.instructorPasswordCheck.trim() !== this.state.instructorPassword.trim()) {
            this.setState({passwordMatchError: true, passwordMatchHelperText: "Passwords do not match"});
            return false;
        }
        return true;
    }

    // Check that all inputs are filled in
    simpleInformationCheck() {

        let errors = {};
        let allValid = true;

        if (this.state.instructorFirst.length < 1){
            errors.firstNameError = true;
            errors.firstNameHelperText = "Required";
            allValid = false;
        }
        if (this.state.instructorLast.length < 1){
            errors.lastNameError = true;
            errors.lastNameHelperText = "Required";
            allValid = false;
        }

        if (this.state.instructorUsername.includes("@")){
            errors.usernameError = true;
            errors.usernameHelperText = "Your username cannot be the same as your email or contain the '@' symbol";
            allValid = false;
        }

        if (this.state.instructorUnitedState.length < 1){
            errors.stateError = true;
            errors.stateHelperText = "Required";
            allValid = false;
        }
        if (this.state.instructorCity.length < 1){
            errors.cityError = true;
            errors.cityHelperText = "Required";
            allValid = false;
        }

         if (!this.isEmailValid()){
             allValid = false;
         }

        if (this.state.instructorUsername.length < 1){
            errors.usernameError = true;
            errors.usernameHelperText = "Required";
            allValid = false;
        }

        if (this.state.instructorPassword.length < 1){
            errors.passwordError = true;
            errors.passwordHelperText = "Required";
            allValid = false;
        }

        this.setState(errors);

        return allValid
    }

    render() {

        const isMobile = window.matchMedia("(max-width: 1025px)").matches;
        let cardContainerCSS = isMobile ? cardContainerStyleMobile : cardContainerStyle;
        let nameDivCSS = isMobile ? nameDivMobile : nameDiv;
        let subInputsCSS = isMobile ? instructorSubInputsMobile : instructorSubInputs;
        let trainingCenterInputCSS =  isMobile ? trainingCenterInputMobile : trainingCenterInput;

        return (
            <Paper elevation={isMobile ? 0 : 4} style={cardContainerCSS}>
                <MuiThemeProvider theme={theme}>
                    <div style={informationDivCenter}>
                        <h2 style={h2Style}>Training Center</h2>

                        <TextField style={trainingCenterInputCSS}
                                   label="Center Name"
                                   variant={"outlined"}
                                   InputProps={{
                                       endAdornment: <InputAdornment position="end">
                                           <Tooltip title={"The name of the training center you teach at"}>
                                               <HelpOutline color={"action"}/>
                                           </Tooltip>
                                       </InputAdornment>,
                                   }}
                                   onChange={evt => this.setState({trainingCenterName: evt.target.value})}
                        />

                        <TextField style={trainingCenterInputCSS}
                                   label="Center ID"
                                   variant={"outlined"}
                                   onChange={evt => this.setState({trainingCenterID: evt.target.value})}
                        />

                    </div>

                    <div style={informationDivInstructor}>
                        <h2 style={h2Style}>Instructor</h2>

                        <div style={nameDivCSS}>
                            <TextField style={subInputsCSS}
                                       error={this.state.firstNameError}
                                       helperText={this.state.firstNameHelperText}
                                       label="First Name"
                                       variant={"outlined"}
                                       onChange={evt => this.setState({instructorFirst: evt.target.value})}
                                       onFocus={() => this.setState({firstNameError: false, firstNameHelperText: ''})}
                            />

                            <TextField style={subInputsCSS}
                                       error={this.state.lastNameError}
                                       helperText={this.state.lastNameHelperText}
                                       label="Last Name"
                                       variant={"outlined"}
                                       onChange={evt => this.setState({instructorLast: evt.target.value})}
                                       onFocus={() => this.setState({lastNameError: false, lastNameHelperText: ''})}
                            />
                        </div>

                        <div style={nameDivCSS}>
                            <FormControl style={subInputsCSS}
                                         error={this.state.stateError}
                                         variant="outlined"
                                         onFocus={() => this.setState({stateError: false, stateHelperText: 'required'})}
                            >
                                <InputLabel ref={"inputLabel"} id="demo-simple-select-outlined-label">
                                    State
                                </InputLabel>

                                <Select
                                    value={this.state.instructorUnitedState}
                                    onChange={evt => this.setState({instructorUnitedState: evt.target.value})}
                                    labelWidth={40}
                                >
                                    {unitedStates.map((state,index) => {
                                        return <MenuItem key={index} value={state}>{state}</MenuItem>
                                    })}
                                </Select>
                                {this.state.stateError && <FormHelperText>Required</FormHelperText>}
                            </FormControl>

                            <TextField style={subInputsCSS}
                                       error={this.state.cityError}
                                       helperText={this.state.cityHelperText}
                                       label="City"
                                       variant={"outlined"}
                                       onChange={evt => this.setState({instructorCity: evt.target.value})}
                                       onFocus={() => this.setState({cityError: false, cityHelperText: ''})}
                            />
                        </div>

                        <TextField style={instructorInputs}
                                   error={this.state.emailError}
                                   helperText={this.state.emailHelperText}
                                   label="Email"
                                   variant={"outlined"}
                                   onChange={evt => this.setState({instructorEmail: evt.target.value})}
                                   onFocus={() => this.setState({emailError: false, emailHelperText: ''})}
                        />

                        <TextField style={instructorInputs}
                                   error={this.state.usernameError}
                                   helperText={this.state.usernameHelperText}
                                   label="Username"
                                   variant={"outlined"}
                                   onChange={evt => this.setState({instructorUsername: evt.target.value})}
                                   onFocus={() => this.setState({usernameError: false, usernameHelperText: ''})}
                        />

                        <TextField style={instructorInputs}
                                   error={this.state.passwordError}
                                   helperText={this.state.passwordHelperText}
                                   label="Password"
                                   variant={"outlined"}
                                   type={"password"}
                                   onChange={evt => this.setState({instructorPassword: evt.target.value})}
                                   onFocus={() => this.setState({passwordError: false, passwordHelperText: ''})}
                        />

                        <TextField style={instructorInputs}
                                   error={this.state.passwordMatchError}
                                   helperText={this.state.passwordMatchHelperText}
                                   label="Confirm Password"
                                   variant={"outlined"}
                                   type={"password"}
                                   onChange={evt => this.setState({instructorPasswordCheck: evt.target.value})}
                                   onFocus={() => this.setState({passwordMatchError: false, passwordMatchHelperText: ''})}
                                   onBlur={() => this.doPasswordsMatch()}
                        />

                    </div>


                    <Button
                        disabled={this.state.isProcessingRequest}
                        variant="contained"
                        color="primary"
                        size="large"
                        style={joinButtonStyle}
                        onClick={this.sendInformation}
                    >
                        Register
                        <PulseLoader
                            css={overrideLoading}
                            sizeUnit={"px"}
                            size={isMobile ? 9 : 12}
                            color={'#FFFFFF'}
                            loading={this.state.isProcessingRequest}
                        />
                    </Button>

                </MuiThemeProvider>
            </Paper>
        );
    }
}

const theme = createMuiTheme({
    palette: {
        primary: { 500: "#2180CE" },
    },
    typography: { useNextVariants: true },
});


const cardContainerStyle = {
    display: 'grid',
    gridTemplateAreas: "'tc ins' 'button button'",
    textAlign: 'center',
    alignContent: 'start',
    justifyItems: 'center',
    width: "90vw",
    maxWidth: "1070px",
    rowGap: '1rem',
    minHeight: '100%',
    padding: '2rem',

    // borderBottom: "solid 8px #2180CE",
};

const cardContainerStyleMobile = {
    display: 'grid',
    gridTemplateAreas: " 'tc' 'ins' 'button'",
    textAlign: 'center',
    alignContent: 'center',
    justifyItems: 'center',
    width: '100%',
    rowGap: "20px",
    padding: '0.5rem',
};

const h2Style = {
    color: '#2180CE',
    fontSize: 'calc(20px + (24 - 20) * ((100vw - 300px) / (1600 - 300)))',
    marginBottom: "1vh"
};

const trainingCenterInput = {
    width: '100%',
    height: 'auto',
    marginBottom: '0.8rem',
    textAlign: 'center',
    fontSize: '18px',
    outline: "0",
};

const trainingCenterInputMobile = {
    width: '100%',
    height: 'auto',
    marginBottom: '0.8rem',
    textAlign: 'center',
    fontSize: '18px',
    outline: "0",
};

const instructorInputs = {
    width: '100%',
    height: 'auto',
    textAlign: 'center',
    borderRadius: '10px',
    borderColor: '#2180CE',
    fontSize: '18px',
    boxSizing: 'content-box',
    borderStyle: 'solid'
};

const instructorSubInputs = {
    width: '100%',
    height: 'auto',
    textAlign: 'center',
    fontSize: '18px',
};

const instructorSubInputsMobile = {
    width: '100%',
    height: 'auto',
    textAlign: 'center',
    borderRadius: '10px',
    borderColor: '#2180CE',
    fontSize: '18px',
    marginTop: '0.5rem',
    boxSizing: 'content-box',
    borderStyle: 'solid'
};

const informationDivCenter = {
    gridArea: "tc",
    display: 'flex',
    flexDirection: "column",
    rowGap: "0.8rem",
    alignItems: 'center',
    width: '80%'
};

const informationDivInstructor = {
    gridArea: "ins",
    display: 'grid',
    gridTemplateRows: "1fr",
    rowGap: "0.8rem",
    alignItems: 'center',
    width: '80%'
};

const nameDiv = {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '2px'
};

const nameDivMobile = {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignContent: 'start',
    justifyItems: 'center'
};

const joinButtonStyle = {
    gridArea: "button",
    maxHeight: '50px',
    width: '55%',
    textAlign: 'center',
    background: '#2180CE',
    color: '#FFFFFF',
    marginTop: '1rem',
    fontSize: '18px',
    textTransform: "none"
};

const overrideLoading = css`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2180CE;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

// PropTypes
AD_RegistrationInformationCard.propTypes = {
    changePageFunc: PropTypes.func.isRequired,
    updateUserInfo: PropTypes.func.isRequired
};


export default AD_RegistrationInformationCard;