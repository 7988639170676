import React, {Component} from 'react';
import AD_Showcase from "./AD_Showcase";
import appleStoreImg from "../../src-assets/app_store.svg"
import festive from "../../src-assets/undraw_festivities.svg"

// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios?answertab=votes#tab-top
// TODO: [P1] Fix mem leak here https://stackoverflow.com/questions/31023308/clearinterval-is-not-working-in-reactjs

class AD_RegistrationCompleted extends Component {

    // Start redirect countdown if the user is on mobile
    componentDidMount() {
        if (window.matchMedia("(max-width: 1025px)").matches){
            this.timerID = setInterval(
                () => this.tick(),
                1000
            );
        }
    }

    tick(){
        if (this.state.countdown > 0){
            this.setState({countdown: this.state.countdown - 1});
        } else {
            clearInterval(this.timerID);
            window.location.href = "https://cprforms.com/app/";
        }
    }

    state = {
        countdown: 10
    };

    render() {
        const isMobile = window.matchMedia("(max-width: 1025px)").matches;
        let containerCSS = isMobile ? containerStyleMobile : containerStyle;
        let illustrationCSS = isMobile ? illustrationCSSMobile : illustrationCSSDesktop
        let callActionContainerCSS = isMobile ? callActionContainerStyleMobile : callActionContainerStyle;

        return (
            <div id={"adrc-container"} style={containerCSS}>
                <div id={"call-action-container"} style={callActionContainerCSS}>

                    <div style={textContainer}>
                        <h1 style={{fontSize: "40px", fontWeight: "bold", marginBottom: "2rem"}}>You're all set!</h1>
                        <img
                            style={illustrationCSS}
                            src={festive}
                            alt="festivities"
                        />
                        <p style={{fontSize: "25px", marginTop: '4rem'}}>
                            <span>
                            Please click <a style={downloadImg} href={"https://cprforms.com/app/"}>here</a> {`if you are not redirected to the Dashboard's login page in ${this.state.countdown} seconds...`}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

const containerStyle = {
    height:" 100%",
    width:" 100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
};

const containerStyleMobile = {
    height:" 100%",
    width:" 100%",
};

const callActionContainerStyle = {
    height:" 100%",
    width:" 100%",
    display: "grid",
    gridColumn: "auto",
    alignItems: "center",
    justifyContent: "center"
};

const callActionContainerStyleMobile = {
    height:" auto",
    width:" 100%",
    display: "grid",
    gridColumn: "auto",
    alignItems: "center",
    justifyContent: "center"
};

const illustrationCSSMobile = {
    width: '90%'
}

const illustrationCSSDesktop = {
    width: '50%'
}

const textContainer = {
    textAlign: "center"
};

const downloadImg = {
    width: "50%",
    alignSelf: "end",
    justifySelf: "center",
    textDecoration: 'underline'
};

AD_RegistrationCompleted.propTypes = {};

export default AD_RegistrationCompleted;