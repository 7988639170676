import React, {Component} from 'react';
import AD_Home from './AD_Home'
import AD_DisplayPage from "./AD_DisplayPage";
import formgenImg from "../../src-assets/formgeneration.png"
import manageclassImg from "../../src-assets/manageclass.png"

class AD_DisplayPageContainer extends Component {

    state = {
        pages: [
            {
                index: 0,
                title: "",
                description: "",
                image: '',
                reference: React.createRef()
            },

            {
                index: 1,
                title: "Manage Your Classes",
                description: "Schedule, edit, and view your classes. Multi-platform, cloud based service",
                image: manageclassImg,
                reference: React.createRef()
            },
            {
                index: 2,
                title: "Form Generation",
                description: "Auto Generate all of your student's forms in seconds. Save to cloud, email, or print them directly from your device",
                image: formgenImg,
                reference: React.createRef()
            }
        ]
    };

    nextPage = (index) => {
        if (index + 1 < this.state.pages.length) {
            this.state.pages[index + 1].reference.current.scrollIntoView({behavior: "smooth"});
        } else {
            window.scrollTo({top: 0, behavior: "smooth"});
        }

    };

    render() {
        return this.state.pages.map((adPage) => {

            // The main page is a different component (<AD_Home/>), so we need a special case to return it.
            if (adPage.index === 0) {
                return (
                    <AD_Home key={adPage.index} adPage={adPage} arrowDownFunction={this.nextPage}/>
                )

            } else {

                return (
                    <AD_DisplayPage key={adPage.index} adPage={adPage} arrowDownFunction={this.nextPage}/>
                )
            }
        });
    }
}

// CSS


export default AD_DisplayPageContainer;