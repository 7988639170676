import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {PulseLoader} from "react-spinners";
import {css} from "@emotion/react";
import {CardElement, injectStripe, CardNumberElement, CardExpiryElement,CardCVCElement} from "react-stripe-elements";
import Tooltip from "@material-ui/core/Tooltip";

class UpdatePayment extends Component {
    state = {
        isUpdateDialogOpen: false,
        isProcessingPayment: false,

        showTooltip: false
    }

    submitInfo = async () => {

        this.setState({isProcessingPayment: true})
        let sessionToken
        let sessionResponse = await fetch('https://cprforms.com/api/v1/payauthenticate', {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                username: this.props.username
            })
        });

        if (sessionResponse.ok){
            let res = await sessionResponse.json();
            sessionToken = res.token
        } else {
            sessionToken = "FAILED-TO-OBTAIN-TOKEN"
        }


        // Generate Stripe token with CC info to be sent to server
        let {token} = await this.props.stripe.createToken({name: this.state.cardHolderName});
        let snackbarText;
        let snackBarStyle;

        let response = await fetch('https://cprforms.com/api/v1/payment2', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": sessionToken,
                'sec-fetch-site': 'same-origin',
                'sec-fetch-mode': 'cors'
            },
            body: JSON.stringify({
                sub: "Basic",
                token: token.id,
                username: this.props.username,
                email: this.props.userWebProfile.email,
                recurring: true
            })
        });

        if (response.ok){
            snackbarText = "Your payment information has been updated"
            snackBarStyle = "#4caf50"
        } else {
            snackbarText =  "There was an error updating your payment info, please try again later or contact support"
            snackBarStyle = "#f14335"
        }

        this.setState({isProcessingPayment: false})

        // Calls getUserProfile() in parent: Dashboard.js to update the userWebProfile which is used for flow of the ui
        await this.props.userWebProfile.updateCallback()

        // Display a snackbar in AD_SettingsPage
        this.props.displaySnack({
            openSnackbar: true,
            snackbarText: snackbarText,
            snackbarStyle: snackBarStyle
        })
    }

    handleCloseUpdateDialog = () => {
        this.setState({isUpdateDialogOpen: false})
    }

    render() {
        return (
            <div>
                <ListItem>
                    <ListItemText
                        primary={"Update Payment Info"}
                        secondary={"We noticed that you haven't submitted any payment info, if you want to keep using InstructorTK, please update your payment info"}
                    />
                    <div style={this.props.isMobile ? {height: "100%", width: "100%"} : {}}>
                        <ListItemSecondaryAction>
                            {this.props.userWebProfile.userStatus === -1 &&
                                <Tooltip arrow disableFocusListener disableTouchListener title={"You must confirm your email before adding payment information"}>
                                    <span style={{display: "block"}}>
                                        <Button
                                            color="primary"
                                            disabled
                                        >
                                            Update
                                        </Button>
                                    </span>
                                </Tooltip>
                            }

                            {this.props.userWebProfile.userStatus !== -1 &&
                                <Button
                                    color="primary"
                                    onClick={() => this.setState({isUpdateDialogOpen: true})}
                                >
                                    Update
                                </Button>
                            }
                        </ListItemSecondaryAction>
                    </div>
                </ListItem>

                <Dialog
                    open={this.state.isUpdateDialogOpen}
                    onClose={this.handleCloseUpdateDialog}
                    aria-labelledby="alert-dialog-cancel-sub"
                    aria-describedby="alert-dialog-cancel-sub-desc"
                >

                    <DialogTitle id="form-dialog-title">Update Payment Info</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Update your payment settings below. You will not be charged until your trial ends.
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            id="code"
                            label="Cardholder Name"
                            onChange={evt => this.setState({name: evt.target.value})}
                            fullWidth
                            error={this.state.nameError}
                            helperText={this.state.nameError ? "Name is required" : null}
                        />

                        {this.props.isMobile &&
                            <form style={formStyle}>
                                <div style={stripeElementStyle}>
                                    <CardNumberElement {...createOptions()}/>
                                </div>

                                <div style={stripeElementStyle}>
                                    <CardExpiryElement {...createOptions()}/>
                                </div>

                                <div style={stripeElementStyle}>
                                    <CardCVCElement {...createOptions()}/>
                                </div>
                            </form>
                        }

                        {!this.props.isMobile &&
                            <div style={stripeElementStyle}>
                                <CardElement {...createOptions()}/>
                            </div>
                        }
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.handleCloseUpdateDialog} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={this.submitInfo} color="primary">
                            Submit
                            <PulseLoader
                                css={overrideLoading}
                                sizeUnit={"px"}
                                size={this.state.isMobile ? 6 : 9}
                                color={'#2180CE'}
                                loading={this.state.isProcessingPayment}
                            />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const createOptions = () => {
    return {
        style: {
            base: {
                display: "block",
                margin: "10px 0 20px 0",
                padding: "10px 14px",
                fontSize: "1em",
                fontFamily: 'Source Code Pro, monospace',
                borderBottom: "2px solid #949494",
                outline: "0",
                borderRadius: "4px",
                background:" white"
            },
            invalid: {
                color: '#c23d4b',
            },
        }
    }
};

const formStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",

    marginTop: "20px"
}

const stripeElementStyle = {
    width: "100%",
    display: "block",
    margin: "10px 0 20px 0",
    padding: "10px 0",
    fontSize: "1em",
    fontFamily: 'Source Code Pro, monospace',
    borderBottom: "1px solid gray",
    outline: "0",
    background:" white"
}

const overrideLoading = css`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 10px;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

UpdatePayment.propTypes = {
    username: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    userWebProfile: PropTypes.object.isRequired,
    isMobile:  PropTypes.bool.isRequired,
    displaySnack: PropTypes.func.isRequired
};

export default injectStripe(UpdatePayment);