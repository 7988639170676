import React, {Component} from 'react';
import './css/Registration.css'
import AD_RegistrationContainer from "./sub-components/AD_RegistrationContainer";
import NavigationBar from "./NavigationBar";


// TODO: SET A MIN HEIGHT FOR ALL CARDS
class Registration extends Component {

    titles =  ["I am a(n)...", "Admin Plans", "Instructor Plans", "Registration", "Verification", "Registration Complete!"];

    state = {
        currentTitle: this.titles[0]
    };

    componentDidMount() {

        // Forces this component and it's sub-components to be re-rendered if the screen goes to a mobile size
        window.onresize = () => {
            this.setState({isMobile: window.matchMedia("(max-width: 1025px)")})
        }
    }

    changeTitle = (stage) => {
        this.setState({currentTitle: this.titles[stage]})
    };

    render() {

        const isMobile = window.matchMedia("(max-width: 1025px)").matches;

        const registrationContainerCSS = isMobile ? registrationContainerStyleMobile : registrationContainerStyle;
        const cardContainerCSS = isMobile ? cardContainerStyleMobile : cardContainerStyle;

        return (
            <div style={registrationContainerCSS} id={"registrationContainer"}>
                <NavigationBar/>
                <h2 style={headerStyle}>{this.state.currentTitle}</h2>
                <div style={cardContainerCSS} id={"cardContainer"}>
                    <AD_RegistrationContainer titleFunction={this.changeTitle}/>
                </div>
            </div>
        );
    }
}

// CSS
const registrationContainerStyle = {
    height: "100vh",
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
};

const registrationContainerStyleMobile = {
    height: "100%"
};

const headerStyle  = {
    marginTop: '1rem',
    fontSize: 'calc(32px + (36 - 32) * ((100vw - 300px) / (1600 - 300)))',
    textAlign: 'center',
    color: '#2180CE'
};

const cardContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

};

const cardContainerStyleMobile = {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    gridAutoFlow: 'row',
    margin: '2vh auto auto auto',
    maxWidth: '100%',
    height: '100vh',
    gridRowGap: '4vh',
    gridAutoRows: 'auto',
    gridTemplateColumns: '1fr',

};

export default Registration;