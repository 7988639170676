import React, {Component} from 'react';
import NavigationBar from "./NavigationBar";
import TextField from "@material-ui/core/TextField";
import {PulseLoader} from "react-spinners";
import Button from "@material-ui/core/Button";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import {css} from "@emotion/react";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import {Link} from "react-router-dom";
import finalIllustration from "../src-assets/undraw_progressive.svg";


// TODO: Add illustration at the bottom to fill in empty space
// TODO: 401 - timeout message if they dont reset in the alotted time
// TODO: Add toast at the bottom
class PasswordRecovery extends Component {

    componentDidMount() {

        // Initial media match
        this.setState({isMobile: window.matchMedia("(max-width: 850px)").matches});

        window.onresize = () => {
            // Subsequent  media matches on resize
            this.setState({isMobile: window.matchMedia("(max-width: 850px)").matches})
        }
    }

    checkUsernameExists = async () => {

        if (this.state.username.length < 2){
            this.setState({usernameError: true, usernameHelperText: "This username does not exist"});
            return
        }

        this.setState({isProcessingRequest: true});
        await fetch('https://cprforms.com/api/v1/users/' + this.state.username + '/passwordreset', {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                "":''
            }),
        }).then(response => {

            if (response.ok) {
                response.text().then(response => {
                    this.setState({recoveryStage: 1})
                });
            }

            else if (response.status === 404 || response.status === 409){
                this.setState({usernameError: true, usernameHelperText: "This username does not exist"});
            }
        });
        this.setState({isProcessingRequest: false});
    };

    authenticateNewPassword = async () => {

        if (this.state.code.length < 6){
            this.setState({usernameError: true, usernameHelperText: "Invalid code"});
            return
        }

        if (this.state.newPassword.length < 6){
            this.setState({newPasswordError: true, newPasswordHelperText: "Password must be at least 6 characters"});
            return
        }

        if (!this.verifyPasswordsMatch()){
            this.setState({passwordConfirmError: true, passwordConfirmHelperText: "Passwords don't match"});
            return
        }

        this.setState({isProcessingRequest: true});
        await fetch('https://cprforms.com/api/v1/users/' + this.state.username + '/passwordreset', {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                "token":this.state.code,
                "password":this.state.newPassword
            }),
        }).then(response => {

            if (response.ok) {
                response.text().then(response => {
                    this.setState({recoveryStage: 2})
                });
            }

            else if (response.status === 401){
                this.setState({codeError: true, codeHelperText: "Invalid Code"});
            }
        });
        this.setState({isProcessingRequest: false});
    };

    state = {
        recoveryStage: 0,
        isProcessingRequest: false,

        username: "",
        usernameError: false,
        usernameHelperText: '',

        code: "",
        codeError: false,
        codeHelperText: "",

        newPassword: "",
        newPasswordError: false,
        newPasswordHelperText: "",

        passwordConfirm: "",
        passwordConfirmError: false,
        passwordConfirmHelperText: "",

        showSnackbar: false
    };

    handleClose (){
        this.setState({showSnackbar: false})
    }

    verifyPasswordsMatch () {
        return this.state.newPassword === this.state.passwordConfirm;
    };

    render() {

        let recoveryCSS = this.state.isMobile ? recoveryCardStyleMobile : recoveryCardStyle;
        let titleCSS = this.state.isMobile ? titleStyleMobile : titleStyle;

        return (
            <div id={"pageContainer"}>
                <NavigationBar/>

                <div id={"contentContainer"} style={contentContainerStyle}>

                    <div id={"recoveryCard"} style={recoveryCSS}>

                        <h1 style={titleCSS}>Password Recovery</h1>

                        <div style={instructionStyle}>

                            {this.state.recoveryStage === 0 && <p style={this.state.isMobile ?  {fontSize: "16px", textAlign: "center"} : {fontSize: "18px"}}>
                                Please provide the username that you used when you signed up for your account. <br/><br/>
                                We will send you an email that will allow you to reset your password.
                            </p>}

                            {this.state.recoveryStage === 1 && <p style={this.state.isMobile ?  {fontSize: "16px", textAlign: "center"} : {fontSize: "18px"}}>
                                Please check your email for an authorization code, it should arrive within 1 minute. <br/><br/>
                                Once received, enter the code along with your new password.
                            </p>}

                            {this.state.recoveryStage === 2 && <p style={this.state.isMobile ?  {fontSize: "16px", textAlign: "center"} : {fontSize: "18px"}}>
                                Your password has been reset successfully! <br/><br/>
                                You may now login with your new password.
                            </p>}

                        </div>

                        {/* Process Stage 0*/}
                        {this.state.recoveryStage === 0 && <div id={"stageOneDiv"} style={this.state.isMobile ? stageOneStyleMobile: stageOneStyle}>
                            <MuiThemeProvider theme={theme}>
                                <TextField style={recoveryInputStyle}
                                           error={this.state.usernameError}
                                           helperText={this.state.usernameHelperText}
                                           label="Username"
                                           autoComplete={"gddfds"}
                                           variant={"outlined"}
                                           onChange={evt => this.setState({username: evt.target.value})}
                                           onFocus={() => this.setState({usernameError: false, usernameHelperText: ''})}
                                />

                                <Button
                                    disabled={this.state.isProcessingRequest}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    style={actionButtonStyle}
                                    onClick={this.checkUsernameExists}
                                >
                                    Send Email
                                    <PulseLoader
                                        css={overrideLoading}
                                        sizeUnit={"px"}
                                        size={this.state.isMobile ? 9 : 11}
                                        color={'#FFFFFF'}
                                        loading={this.state.isProcessingRequest}
                                    />
                                </Button>
                            </MuiThemeProvider>
                        </div>}

                        {/* Process Stage 1*/}
                        {this.state.recoveryStage === 1 && <div id={"stageOneDiv"} style={this.state.isMobile ? stageOneStyleMobile: stageOneStyle}>
                            <MuiThemeProvider theme={theme}>
                                <TextField style={recoveryInputStyle}
                                           error={this.state.codeError}
                                           helperText={this.state.codeHelperText}
                                           label="Code"
                                           variant={"outlined"}
                                           autoComplete={"gddfds"}
                                           onChange={evt => this.setState({code: evt.target.value})}
                                           onFocus={() => this.setState({codeError: false, codeHelperText: ''})}
                                />

                                <TextField style={recoveryInputStyle}
                                           error={this.state.newPasswordError}
                                           helperText={this.state.newPasswordHelperText}
                                           label="New Password"
                                           variant={"outlined"}
                                           autoComplete={"gddfds"}
                                           type={"password"}
                                           onChange={evt => this.setState({newPassword: evt.target.value})}
                                           onFocus={() => this.setState({newPasswordError: false, newPasswordHelperText: ''})}
                                />

                                <TextField style={recoveryInputStyle}
                                           error={this.state.passwordConfirmError}
                                           helperText={this.state.passwordConfirmHelperText}
                                           label="Confirm New Password"
                                           variant={"outlined"}
                                           autoComplete={"gddfds"}
                                           type={"password"}
                                           onChange={evt => this.setState({passwordConfirm: evt.target.value})}
                                           onFocus={() => this.setState({passwordConfirmError: false, passwordConfirmHelperText: ''})}
                                />

                                <Button
                                    disabled={this.state.isProcessingRequest}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    style={actionButtonStyle}
                                    onClick={this.authenticateNewPassword}
                                >
                                    Change Password
                                    <PulseLoader
                                        css={overrideLoading}
                                        sizeUnit={"px"}
                                        size={this.state.isMobile ? 9 : 11}
                                        color={'#FFFFFF'}
                                        loading={this.state.isProcessingRequest}
                                    />
                                </Button>
                            </MuiThemeProvider>
                        </div>}

                        {/* Process Stage 2*/}
                        {this.state.recoveryStage === 2 && <div id={"stageTwoDiv"} style={stageTwoStyle}>

                            <img style={{width: "50%", minWidth: "150px"}} src={finalIllustration} alt="Login"/>

                            <MuiThemeProvider theme={theme}>
                                <Link to={"/login"} style={{ textDecoration: 'none', marginTop: "2rem"}}>
                                    <Button
                                        disabled={this.state.isProcessingRequest}
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        style={actionButtonStyle}
                                    >
                                        Login
                                    </Button>
                                </Link>

                            </MuiThemeProvider>

                        </div>}

                    </div>
                </div>

                <MuiThemeProvider theme={theme}>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={this.state.showSnackbar}
                        autoHideDuration={4000}
                        onClose={() => this.handleClose()}
                    >
                        <SnackbarContent
                            style={{backgroundColor:'teal'}}
                            message={<span id="client-snackbar">Hello World</span>}
                        />
                    </Snackbar>
                </MuiThemeProvider>

            </div>
        );
    }
}

const theme = createMuiTheme({
    palette: {
        primary: { 500: "#2180CE" },
    },
    typography: { useNextVariants: true },
});

const contentContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px"
};

const recoveryCardStyle = {
    display: "grid",
    gridTemplateAreas: "'title title' 'instruction stage'",
    alignItems: "start",
    justifyContent: "start",
    padding: "20px",
};

const recoveryCardStyleMobile = {
    display: "grid",
    gridTemplateAreas: "'title' 'instruction' 'stage'",
    alignItems: "start",
    justifyContent: "start",
    padding: "10px",
};

const titleStyle = {
    gridArea: "title",
    padding: "20px"
};

const titleStyleMobile = {
    gridArea: "title",
    padding: "20px",
    textAlign: "center"
};

const instructionStyle = {
    gridArea: "instruction",
    width: "100%",
    padding: "0 20px 20px 20px"
};

const stageOneStyle = {
    gridArea: "stage",
    height: "100%",
    width: "100%",
    padding: "0 20px 20px 20px"
};

const stageOneStyleMobile = {
    gridArea: "stage",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    padding: "0 20px 20px 20px"
};

const stageTwoStyle = {
    gridArea: "stage",
    display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    padding: "0 20px 20px 20px"
};

const recoveryInputStyle = {
    width: '100%',
    height: 'auto',
    marginBottom: '1rem',
    textAlign: 'center',
    borderRadius: '10px',
    borderColor: '#2180CE',
    fontSize: '18px',
    boxSizing: 'content-box',
    borderStyle: 'solid'
};

const actionButtonStyle = {
    textTransform: "none"
};

const overrideLoading = css`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2180CE;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 5px;
`;


PasswordRecovery.propTypes = {};

export default PasswordRecovery;