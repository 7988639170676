import React, {Component} from 'react';
import PropTypes from 'prop-types';
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import {Elements, StripeProvider} from "react-stripe-elements";
import UpdatePayment from "./account-settings-components/UpdatePayment";
import CancelSub from "./account-settings-components/CancelSub";
import VerifyAccount from "./account-settings-components/VerifyAccount";

class AdAccountSettings extends Component {


    componentDidMount() {

    }

    render() {

        const isMobile = window.matchMedia("(max-width: 750px)").matches;
        return (
            <Paper>
                <MuiThemeProvider theme={theme}>
                    <List>

                        {(this.props.userWebProfile.userStatus === -1) &&
                            <VerifyAccount
                                isMobile={isMobile}
                                token={this.props.token}
                                username={this.props.username}
                                displaySnack={this.props.displaySnack}
                                userWebProfile={this.props.userWebProfile}
                            />
                        }

                        {(this.props.userWebProfile.userStatus === -1 || this.props.userWebProfile.message.instructorSubscription === "None") &&
                            <StripeProvider apiKey="pk_live_GWpgxirfxWtCkzoOjcc1qplH00id40Dfj9">
                                <Elements>
                                    <UpdatePayment
                                        username={this.props.username}
                                        token={this.props.token}
                                        userWebProfile={this.props.userWebProfile}
                                        isMobile={isMobile}
                                        displaySnack={this.props.displaySnack}
                                    />
                                </Elements>
                            </StripeProvider>
                        }

                        <CancelSub isMobile={isMobile} token={this.props.token} username={this.props.username} displaySnack={this.props.displaySnack}/>

                    </List>
                </MuiThemeProvider>
            </Paper>
        );
    }
}

const theme = createMuiTheme({
    palette: {
        primary: { 500: "#2180CE" },
        secondary: {A400: "#C62828"}
    },
    typography: { useNextVariants: true }
});


AdAccountSettings.propTypes = {
    username: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    userWebProfile: PropTypes.object.isRequired,
    displaySnack: PropTypes.func.isRequired
};
export default AdAccountSettings;