import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import EmailIcon from '@material-ui/icons/Email';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";



class NavigationBar extends Component {

    state = {
        isOpen: false
    };

     toggleDrawer = (open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({isOpen: open});
    };

    render() {

        let brandingCSS =  window.matchMedia("(max-width: 750px)").matches ? brandingStyleMobile : brandingStyle;

        return (
            <div style={headerStyle}>
                <div style={navStyle} className="nav-container">
                    <MuiThemeProvider theme={theme}>
                        <IconButton color={"primary"}  alt="back" onClick={() => this.setState({isOpen: true})}>
                            <MenuIcon/>
                        </IconButton>
                    </MuiThemeProvider>

                    <Link to={"/"} style={brandingCSS}>
                        <img src='assets/ToolKit_Large.svg' style={brandingCSS} className="branding" alt="Logo"/>
                    </Link>
                </div>

                <SwipeableDrawer
                    open={this.state.isOpen}
                    onClose={this.toggleDrawer(false)}
                    onOpen={this.toggleDrawer(true)}
                >
                    <div
                        role="presentation"
                        style={{width: 250}}
                        onClick={this.toggleDrawer(false)}
                        onKeyDown={this.toggleDrawer(false)}
                    >

                        <List>
                            <ListItem button key={"Exit"}>
                                <ListItemIcon><CloseIcon/></ListItemIcon>
                            </ListItem>
                            <img src="assets/ToolKit_Compact.svg" alt="Toolkit Compact Logo" style={toolkitMenuLogo}/>
                            <Link to={"/"} style={menuLinkStyle}>
                                <ListItem button key={"Home"}>
                                    <ListItemIcon><HomeIcon/></ListItemIcon>
                                    <ListItemText primary={"Home"}/>
                                </ListItem>
                            </Link>

                            <ListItem button key={"Login"} onClick={()=> window.location.href = "https://cprforms.com/app/"}>
                                <ListItemIcon><PersonIcon/></ListItemIcon>
                                <ListItemText primary={"Login"}/>
                            </ListItem>

                            {/*<Link to={"/terms"} style={menuLinkStyle}>
                                <ListItem button key={"Terms"}>
                                    <ListItemIcon><DescriptionIcon/></ListItemIcon>
                                    <ListItemText primary={"Terms"}/>
                                </ListItem>
                            </Link> */}

                            <Link to={"/questions"} style={menuLinkStyle}>
                                <ListItem button key={"Questions"}>
                                    <ListItemIcon><QuestionAnswerIcon/></ListItemIcon>
                                    <ListItemText primary={"Questions"}/>
                                </ListItem>
                            </Link>

                            <Link to={"/contact"} style={menuLinkStyle}>
                                <ListItem button key={"Contact"}>
                                    <ListItemIcon><EmailIcon/></ListItemIcon>
                                    <ListItemText primary={"Contact"}/>
                                </ListItem>
                            </Link>
                        </List>

                    </div>
                </SwipeableDrawer>
            </div>
        );
    }
}

// CSS
const headerStyle = {
    backgroundColor: 'white',
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 10px 2px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
};

const navStyle = {
    width: '100%',
    margin: 'auto',
    overflow: 'hidden',
    minHeight: '4rem',
    display: 'grid',
    gridTemplateColumns: '1fr 8fr 1fr',
    justifyItems: 'center',
    alignItems: 'center'
};

const brandingStyle = {
    height: '3.8rem',
    justifySelf: 'center'
};

const brandingStyleMobile = {
    height: '3.5rem',
    justifySelf: 'center'
};

const menuLinkStyle = {
    textDecoration: 'none',
    color: "#545454",
    fontSize: "1rem",
    fontWeight: "400"
};

let toolkitMenuLogo = {
    height: "150px",
    width: "100%",
    padding: "10%"
};

const theme = createMuiTheme({
    palette: {
        primary: { 500: "#2180CE" },
    },
    typography: { useNextVariants: true },
});


export default NavigationBar;
