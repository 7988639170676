import React, {Component} from 'react';
import './css/Home.css'
import AD_DisplayPageContainer from "./sub-components/AD_DisplayPageContainer";
import NavigationBar from "./NavigationBar";

// TODO: Replace outdated app images on home screen
// TODO: Font sizing for Manage Classes, etc... is too small on mobile
// TODO: Get different image for Manage Classes, etc...
class Home extends Component {

    state = {

    };

    componentDidMount() {

        // Forces this component and it's sub-components to be re-rendered if the screen goes to a mobile size because it forces refresh on size change
        window.onresize = () => {
            this.setState({isMobile: window.matchMedia("(max-width: 1025px)")})
        }
    }

    render() {
        return (
            <div>
                <NavigationBar/>
                <div className="home-container">
                    <AD_DisplayPageContainer style={displayPageContainerStyle}/>
                </div>
            </div>
        );
    }
}


// CSS
const displayPageContainerStyle = {
    borderTop: 'red solid 1px'
};

export default Home;