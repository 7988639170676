import React, {Component} from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import Switch from '@material-ui/core/Switch';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import PropTypes from 'prop-types'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {PulseLoader} from "react-spinners";
import {css} from "@emotion/react";

// TODO: [P2] Responsiveness for iPad Pro
class AD_PaymentCard extends Component {

    state = {
        isAutoRenew: true,
        cardHolderName: '',
        planSelected: this.props.userInformation.planType,
        sessionToken: '',
        isProcessingRequest: false
    };

    componentWillMount() {
        this.obtainSessionToken().then(() => {
            // TODO: [P1] handle unsuccessful token
        });
    };


    obtainSessionToken = async () => {

        let response = await fetch('https://cprforms.com/api/v1/payauthenticate', {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                username: this.props.userInformation.username
            })
        });

        if (response.ok){
            let res = await response.json();
            this.state.sessionToken = res.token
        }

    };

    getPlanTypePrice = () => {

        let price;
        switch (parseInt(this.state.planSelected)) {
            case 1:
                price = "Start Trial";
                break;
        }
        return price // (this.state.isAutoRenew ? "/yr" : "");
    };

    handleAutoRenewChange = () => {
        this.setState({isAutoRenew: !this.state.isAutoRenew})
    };

    // Generates stripe token and sends
    submitStripeInfo = async (e) => {

        // Gray out the submit button while request is being processed to prevent user from sending multiple requests
        this.setState({isProcessingRequest: true});

        // Generate Stripe token with CC info to be sent to server
        let {token} = await this.props.stripe.createToken({name: this.state.cardHolderName});

        let response = await fetch('https://cprforms.com/api/v1/payment2', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.state.sessionToken,
                'sec-fetch-site': 'same-origin',
                'sec-fetch-mode': 'cors'
            },
            body: JSON.stringify({
                sub: "Basic",
                token: token.id,
                username: this.props.userInformation.username,
                email: this.props.userInformation.email,
                recurring: this.state.isAutoRenew
            })
        });

        if (response.ok){
            this.props.toggleLoadingScreen();
            this.props.changePageFunc();
        }

        this.setState({isProcessingRequest: false});
    };

    render() {
        let isMobile = window.matchMedia("(max-width: 1025px)").matches;
        let cardContainerCSS = isMobile ? cardContainerStyleMobile : cardContainerStyle;

        return (

            <div style={cardContainerCSS} id="payCardContainer">
                <MuiThemeProvider theme={theme}>
                    <div>
                        <img style={{width:'90%'}} src="assets/CreditCard.svg" alt="" id="creditCardImage"/>

                        <div style={{height: '30px'}}>
                            <img style={{height: '100%', marginRight: '4px'}} src="assets/icons8-visa.svg" alt=""/>
                            <img style={{height: '100%', marginRight: '4px'}} src="assets/icons8-mastercard.svg" alt=""/>
                            <img style={{height: '100%', marginRight: '4px'}} src="assets/icons8-american-express.svg" alt=""/>
                            <img style={{height: '100%', marginRight: '4px'}} src="assets/icons8-discover.svg" alt=""/>
                        </div>

                        <div>
                            <label style={{fontSize: '12px', color: '#2180CE', fontWeight: 'bold', textAlign: 'left', margin:'0'}}>Total</label>
                            <h2 style={{margin: '0'}} id="priceLabel">{this.getPlanTypePrice()}</h2>
                        </div>
                    </div>

                    <TextField style={inputContainerStyle}
                               label="Plan"
                               variant={"outlined"}
                               onChange={evt => this.setState({cardHolderName: evt.target.value})}
                               value={"Instructor - Basic"}
                               disabled={true}
                    />

                    <TextField style={inputContainerStyle}
                               label="Cardholder Name"
                               variant={"outlined"}
                               onChange={evt => this.setState({cardHolderName: evt.target.value})}
                    />

                    <div style={cardElementContainerStyle}>
                        <CardElement {...createOptions()}/>
                    </div>

                    <div id="autoRenewDiv">
                        <h2 style={{fontSize: '14px', color: '#2180CE', fontWeight: 'bold', margin: '0'}}>Auto-Renew</h2>

                        <Switch
                            checked={this.state.isAutoRenew}
                            onChange={this.handleAutoRenewChange}
                            value="checkedA"
                            color="secondary"
                        />

                    </div>


                    <Button
                        disabled={this.state.isProcessingRequest}
                        variant="contained"
                        color="primary"
                        size="large"
                        style={joinButtonStyle}
                        onClick={this.submitStripeInfo}
                    >
                        {this.getPlanTypePrice()}
                        <PulseLoader
                            css={overrideLoading}
                            sizeUnit={"px"}
                            size={isMobile ? 9 : 12}
                            color={'#FFFFFF'}
                            loading={this.state.isProcessingRequest}
                        />
                    </Button>
                </MuiThemeProvider>
                {parseInt(this.state.planSelected) === 1 && <p style={{color:"#aab7c4"}}>You will be charged $24.99/year after the 14-day trial period if you do not cancel</p>}

            </div>
        );
    }
}

const createOptions = () => {
    return {
        style: {
            base: {
                margin: "auto",
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#c23d4b',
            },
        }
    }
};

const cardContainerStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr',
    textAlign: 'center',
    alignContent: 'start',
    justifyItems: 'center',
    rowGap: '1rem',
    width: '40vw',
    minHeight: '100%',
    padding: '2rem',
    borderBottom: "solid 8px #2180CE",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 10px 2px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
};

const cardContainerStyleMobile = {
    display: 'grid',
    gridTemplateColumns: '1fr',
    textAlign: 'center',
    alignContent: 'start',
    justifyItems: 'center',
    rowGap: '0.8rem',
    minWidth: '80%',
    width: '100%',
    maxWidth: "340px",
    padding: "10px"


};

const inputContainerStyle = {
    width: '100%',
    height: 'auto',
    marginBottom: '0.8rem',
    fontSize: '18px',
    textAlign: 'left',
    outline: "0",
};

const cardElementContainerStyle = {
    width: '100%',
    height: "56px",
    padding: "0 14px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: '5px',
    borderWidth: '1px',
    borderColor: '#c4c4c4',
    borderStyle: 'solid',

};

const joinButtonStyle = {
    height: '8vh',
    maxHeight: '50px',
    width: 'auto',
    boxSizing: 'content-box',
    paddingLeft:'2rem',
    paddingRight:'2rem',
    textAlign: 'center',
    background: '#2180CE',
    color: '#FFFFFF',
    textTransform: "none",
    fontSize: 'calc(18px + (26 - 18) * ((100vw - 300px) / (1600 - 300)))',

};

const overrideLoading = css`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2180CE;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

const theme = createMuiTheme({
    palette: {
        secondary: {
            main: '#2180CE'
        }
    },
});

// PropTypes
AD_PaymentCard.propTypes = {
    toggleLoadingScreen: PropTypes.func.isRequired,
    userInformation: PropTypes.object.isRequired,
    planChangeHandler: PropTypes.func.isRequired,
    changePageFunc:  PropTypes.func.isRequired
};

export default injectStripe(AD_PaymentCard);
