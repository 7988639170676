import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import {css} from "@emotion/react";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import AD_AccountSettings from "./AD_AccountSettings";

class AdSettingsPage extends Component {

    state = {
        selectedSettings: 0,

        openSnackbar: false,
        snackbarText: "Default",
        snackbarStyle: 'teal'
    }

    displaySnackbar = (snackbarSettings) => {
        this.setState(snackbarSettings)
    }


    handleCloseSnackbar = () => {
        this.setState({openSnackbar: false})
    }

    render() {
        return (
            <div id={"settingsPageContainer"} style={settingsPage}>
                <h2 style={PageTitle}>Settings</h2>

                <MuiThemeProvider theme={theme}>
                    <div id={"selectorContainer"} style={selectorContainer}>
                        <Button color="primary" variant="contained" style={style} onClick={() => this.setState({selectedSettings: 0})}>Account</Button>
                    </div>

                    {this.state.selectedSettings === 0 &&
                        <AD_AccountSettings
                            username={this.props.username}
                            token={this.props.token}
                            userWebProfile={this.props.userWebProfile}
                            displaySnack={this.displaySnackbar}
                        />
                    }
                </MuiThemeProvider>

                <Snackbar
                    open={this.state.openSnackbar}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={this.handleCloseSnackbar}
                    message={this.state.snackbarText}
                >
                    <SnackbarContent
                        style={{backgroundColor: this.state.snackbarStyle}}
                        message={this.state.snackbarText}
                        action={
                            <React.Fragment>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseSnackbar}>
                                    <CloseIcon fontSize="small"/>
                                </IconButton>
                            </React.Fragment>
                        }
                    />
                </Snackbar>
            </div>
        );
    }
}

const settingsPage = {
    height: "100%",
    width: "100%",
    padding: "20px",
    backgroundColor: "ghostwhite"
}

const PageTitle = {
    marginBottom: "1rem",
    fontWeight: "bold"
};

const selectorContainer = {
    marginBottom: "1rem"
}

// We can use inline-style
const style = {
    borderRadius: 50,
};

const theme = createMuiTheme({
    palette: {
        primary: { 500: "#2180CE" },
        secondary: {A400: "#C62828"}
    },
    typography: { useNextVariants: true }
});

const overrideLoading = css`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 10px;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

AdSettingsPage.propTypes = {
    token: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    userWebProfile: PropTypes.object.isRequired,
};
export default AdSettingsPage;