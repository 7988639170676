import React, {Component} from 'react';
import { Column, Row} from 'simple-flexbox';

class Terms extends Component {

    state = {

    };


    render() {
        return (
    <Column flexGrow={1}>
        <Row wrap horizontal="spaced">
          <Column
            style={{
              backgroundColor: "",
              minWidth: 250,
              maxWidth: "50%",
              padding: 12,
              color: ""
            }}
            flexGrow={1}
            horizontal="center"
          >
            <h3 style={{color: "#2180CE"}}> Privacy Policy </h3>
            <p>
ADALLIGO, LLC, a Michigan Limited Liability Company believes that privacy is important to the success and use of the Internet. This statement sets forth ADALLIGO policy and describes the practices that we will follow with respect to the privacy of the information of users of Instructor Tool Kit (the "Application"). Should you have any questions about this policy or our practices, please send an email to admin@adalligo.com or write us at:</p>
<span>ADALLIGO, LLC</span>
<span>3341 Yosemite Drive</span>
<p>Lake Orion, Michigan 48360</p>

<p><b>What Personal Information We Collect</b></p>

<p>ADALLIGO collects your personal information through the Application when you voluntarily provide it to us.  Personal information collected consists of your name, address, telephone number and/or email address. We also collect information that will allow you to establish a username and password if you would like to do that.</p>

<p><b>How We Use Personal Information That We Collects</b> </p>
<p>Internal Uses</p>
<p>Your personal information is collected through the application when you provide it to us, at which point we organize the information to generate all necessary forms needed for you to obtain certifications through the American Heart Association.</p> 
<p><b>Disclosure of Personal Information to Third Parties (Instructors)</b></p>
<p>We will not disclose any personal information to any third party except your Instructor (as defined below).  You Instructor needs such information for the limited purpose of providing services to you in submitting necessary forms needed for you to obtain certifications through the American Heart Association.</p> 
<p>We do not make any claims or representations as to how your Personal Information will be used after it is in your Instructor’s possession.  When your Personal Information is in your Instructor’s possession we are not in control and not responsible for what may, thereafter, happen with such information.   If you have questions or concerns, please contact your Instructor.  </p>

<span>Furthermore, we do not endorse, sponsor, recommend or otherwise accept any responsibility for such Instructor and their credentials or abilities.</span>  
<p>If you Know of any wrongdoing by an instructor, such as your information not being used for the limited purpose of providing services to you in submitting necessary forms needed for you to obtain certifications through the American Heart Association, please notify us. </p>
<span>An Instructor is the person you have hired and entrusted to prepare you for obtaining certifications through the American Heart Association.  </span>
<p><b>How We Protect Information Online</b></p>
<p>We exercise great care to protect your personal information. This includes, among other things, using industry standard techniques such as firewalls, encryption, and intrusion detection. As a result, while we strive to protect your personal information, we cannot ensure or warrant the security of any information you transmit to us or receive from us. This is especially true for information you transmit to us via email since we have no way of protecting that information until it reaches us since email does not have the security features that are built into our application.
In addition, we limit ADALLIGO employees and contractors access to personal information. Only those employees and contractors with a business reason to know have access to this information. We educate our employees about the importance of maintaining confidentiality of your information.</p>
<span>We review our security arrangements from time to time as we deem appropriate.</span>

<p><b>How can you help protect your information?</b></p>
<p>If you are using a ADALLIGO application for which you registered and choose a password, we recommend that you do not divulge your password to anyone. We will never ask you for your password in an unsolicited phone call or in an unsolicited email. Also remember to sign out of the ADALLIGO application and close your browser window when you have finished your work. This is to ensure that others cannot access your personal information and correspondence if others have access to your computer.</p>

<p><b>Links to Other Applications</b></p>
<p>We want to provide application visitors valuable information, services and products. Featured programs and other application content within the ADALLIGO application may link our users to third party applications. ADALLIGO does not control and is not responsible for practices of any third-party applications.</p>
 
<p><b>Changes to this Privacy Statement</b></p>
<p>From time to time, we may change this privacy statement. For example, as we update and improve our services, new features may require modifications to the privacy statement. Accordingly, please check back periodically.</p>
          </Column>
          <Column
            style={{
              backgroundColor: "",
              minWidth: 250,
              maxWidth: "50%",
              padding: 12,
              color: ""
            }}
            flexGrow={1}
            horizontal="center"
          >
            <h3 style={{color: "#2180CE"}}> Terms and Conditions </h3>
            <p>Welcome to InstructorTK (the "Application"). Please read these terms and conditions carefully before using this Application. By accessing or using the Application, you agree to be bound by these terms and conditions. The provision of information and services on this Application by the owners and operators of ADALLIGO, LLC and is subject to your agreement to the terms and conditions below.</p>
 
<p><b>1.	Scope of These Terms</b></p>

<p>These terms of use apply to your use of the Application. The Application is owned and operated by ADALLIGO, LLC, a Michigan Corporation
We may have other applications that are covered by different terms of use.</p> 

<p><b>2.	Disclaimer of Content</b></p>

 <p>While ADALLIGO attempts to keep all the information on the Application up-to-date, changes can occur quickly change quickly, and this Application should not be considered error-free or as a comprehensive source of all information on a particular topic. ADALLIGO makes no warranties or representations as to the accuracy of the content of the Application, and assumes no responsibility for any consequences relating directly or indirectly to any action or inaction you take based upon the information and material on this Application. Your use of the Application is subject to the additional disclaimers and caveats that may appear throughout these terms and conditions and the ADALLIGO Application. You assume the entire risk of loss in using this Application and materials contained in the Application.</p>
<span>Features and specifications of products or services described or depicted on the Application are subject to change at any time without notice.</span>

<p><b>3.	Copyright, Trademarks and other Intellectual Property</b></p>

<p>Copyright, trademark, patent, and other applicable laws protect the services and materials appearing on the Application. All materials on the Application may be accessed, downloaded or printed for the noncommercial purpose submitting necessary forms needed for you to obtain certifications through the American Heart Association. No other use of these materials may be made without express written permission of the operators of ADALLIGO. Any unauthorized use of the words or images on this Application may violate copyright laws, trademark laws, the laws of privacy and publicity, and civil and criminal statutes.</p>

<p>The Application includes material that is derived in whole or in part from materials that are copyrighted, including the format and layout of the Application. The copyrights are owned by ADALLIGO, or for licensed content, the content providers.
None of the names, trademarks, service marks and logos of ADALLIGO appearing on this Application may be used in any advertising or publicity, or otherwise to indicate ADALLIGO sponsorship of or affiliation with any product or service without express written permission of the operators of ADALLIGO. Nothing contained on the Application should be construed as granting, by implication, estoppel, waiver or otherwise, any license or right of use to any trademark displayed on the Application without the written permission of the owners and operators of ADALLIGO. This Application may contain other proprietary notices and copyright information, the terms of which must be observed and followed.</p>
 
<p><b>4.	Right to Change Terms and Conditions</b></p>
 
<p>ADALLIGO may, at any time and from time to time, change these terms and conditions. Any changes to these terms and conditions will be effective immediately upon posting of the changed terms and conditions on the Application. You agree to review these terms and conditions periodically, and use of the Application following any such change constitutes your agreement to follow and be bound by the terms and conditions as changed.</p>
 
<p><b>5.	Information provided to your Instructor</b></p>
 
<p>We provide your Personal Information (as defined in our Privacy Policy) to your Instructor (as defined in our Privacy Policy).   We do not endorse, sponsor, recommend or otherwise accept any responsibility for such Instructor and their credentials or abilities.  IN PARTICULAR, WE DO NOT ACCEPT ANY LIABILITY ARISING OUT OF ANY ALLEGATION THAT YOUR INSTRUCTOR FAILED TO PROVIDE YOU WITH ANY SERVICES OR MISUSED YOUR PERSONAL INFORMATION IN ANY WAY; SPEFICALLY, THAT YOUR INSTRUCTOR DID NOT USE YOUR PERSONAL INFORMATION FOR THE LIMITED PURPOSE OF PROVIDING SERVICES TO YOU IN SUBMITTING NECESSARY FORMS NEEDED FOR YOU TO OBTAIN CERTIFICATIONS THROUGH THE AMERICAN HEART ASSOCIATION.  </p>

<p><b>6.	Disclaimer of Warranty; Limitation of Liability</b></p>
 
<p>ADALLIGO DISCLAIMS ALL EXPRESS AND IMPLIED WARRANTIES WITH REGARD TO THE INFORMATION, SERVICES AND MATERIALS CONTAINED ON THIS APPLICATION, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. YOUR USE OF THIS APPLICATION IS AT YOUR OWN RISK. ACCESS TO THIS APPLICATION MAY BE INTERRUPTED AND INFORMATION, SERVICES AND MATERIALS MAY NOT BE ERROR-FREE. NONE OF ADALLIGO DOES NOT ASSUMES ANY LIABILITY OR RESPONSIBILITY FOR THE ACCURACY, COMPLETENESS OR USEFULNESS OF ANY INFORMATION, SERVICES AND MATERIALS PROVIDED ON THIS APPLICATION OR YOUR INSTRUCTOR; ADALLIGO ALSO SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF YOUR USE OF, OR INABILITY TO USE, THIS APPLICATION. ALL INFORMATION, SERVICES AND MATERIALS ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED.
YOU ACKNOWLEDGE AND AGREE THAT THE LIMITATIONS SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THIS AGREEMENT AND THIS APPLICATION WOULD NOT BE PROVIDED TO YOU ABSENT SUCH LIMITATIONS.
PLEASE NOTE THAT SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. CHECK YOUR LOCAL LAWS FOR ANY RESTRICTIONS OR LIMITATIONS REGARDING THE EXCLUSION OF IMPLIED WARRANTIES.</p>
 
<p><b>7.	Privacy Policy</b></p>
 
<p>ADALLIGO will treat as confidential all health-related information submitted by you, customers or other visitors to the Application, in accordance with ADALLIGO Privacy Policy. The purpose of our privacy policy is to identify the information we collect online, the steps we take to protect it and your choices regarding how that information is used.</p>
 
<p><b>8.	Indemnification</b></p>
 
<p>You agree to indemnify, defend and hold harmless ADALLIGO and its suppliers and their respective affiliates, employees, officers, directors, agents, servants and representatives of each from any liability, loss, claim, suit, damage, and expense (including reasonable attorneys´ fees and expenses) related to (i) your violation of these terms and conditions and (ii) your posting of material to this Application.</p>
 
<p><b>9.	Applicable Law</b></p>
 
<p>These terms and conditions and the resolution of any dispute related to these terms and conditions shall be construed in accordance with the laws of the State of Michigan. Any dispute between S ADALLIGO and you related to these terms and conditions shall be resolved exclusively by the state and federal courts of the State of Michigan.</p>
<p>This Application can be accessed from the United Stated and other countries worldwide. Since the laws of each State or country may differ, you agree that the statutes and laws of the State of Michigan, without regard to any principles of conflicts of law, will apply to all matters relating to the use of this application.
ADALLIGO makes no representation that material on this application is appropriate or available for use in other locations, and accessing them from territories where their content is illegal is prohibited. Those who choose to access the application from other locations do so on their own initiative and are responsible for compliance with local laws.</p>
 
<p><b>10.	Termination</b></p>
 
<p>These terms and conditions are effective unless and until modified as noted above, or terminated, at any time, by ADALLIGO. If, in the sole discretion of ADALLIGO, you fail to comply with these terms and conditions, ADALLIGO may terminate these terms and conditions without notice and deny you access to the Application.</p>
 
<p><b>11.	Contact Information</b></p>
 
<span>ADALLIGO In, LLC is headquarted in Michigan at the below address:</span>
<span>ADALLIGO, LLC</span>
<span>3341 Yosemite Drive</span>
<span>Lake Orion, Michigan 48360</span>

<p>Specific questions and comments should be directed to us through email tot email to admin@adalligo.com. While we make every effort to respond to all emails within 1 business week, we cannot guarantee a response to every electronic communication.</p>
          </Column>
        </Row>
      </Column>
        );
    }
}

export default Terms;
