import React, {Component} from 'react';
import PropTypes from 'prop-types'


class AD_DisplayPage extends Component {


    render() {
        const {index, image, title, description, reference} = this.props.adPage;

        let isMobile = window.matchMedia("(max-width: 1023px)").matches;
        const contentContainerDesktop = {
            height: '100%',
            display: 'flex',
            flexDirection: index % 2 === 0 ? "row-reverse" : "row",
            alignItems: 'center',
            overflow: 'hidden'
        };

        const contentContainerMobile = {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden'
        };

        // Change CSS for the container of this component depending on screen size
        let displayContainerCSS = isMobile ?  displayContainerStyleMobile : displayContainerStyle;
        let contentContainer = isMobile ? contentContainerMobile : contentContainerDesktop;
        let titleStyleCSS = isMobile ? titleStyleMobile : titleStyle;
        let descriptionStyleCSS = isMobile ? descriptionStyleMobile : descriptionStyle;
        let imageStyleCSS = isMobile ? imageStyleMobile : imageStyle;
        return (
            <div style={displayContainerCSS} ref={reference}>
                <div style={contentContainer} id="content-container">
                    <img style={imageStyleCSS} src={image} alt=""/>
                    <div style={wordsContainerStyle}>
                        <h2 style={titleStyleCSS}>{title}</h2>
                        <p style={descriptionStyleCSS}>{description}</p>
                    </div>
                </div>
                <input type="image" style={arrowDownStyle} src="assets/arrow_down.svg" alt="" onClick={(e) => {
                    this.props.arrowDownFunction(this.props.adPage.index)
                }}/>
            </div>
        );
    }
}

// CSS
const displayContainerStyle = {
    height: 'calc(100vh - 20px)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'space-between',
    padding: '1rem 2rem 0 2rem',
    overflow: 'hidden'
};

const displayContainerStyleMobile = {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 0 2rem',
    overflow: 'hidden'
};

const imageStyle = {
    maxWidth: '50%',
    maxHeight: '100%',
};

const imageStyleMobile = {
    maxWidth: '100%',
    maxHeight: '50%',
    marginBottom: '2rem'
};

const wordsContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    alignItems: 'center'
};

const titleStyle = {
    textAlign: 'center',
    fontSize: '4rem',
    fontWeight: "bold"
};

const titleStyleMobile = {
    textAlign: 'center',
    fontSize: '2rem',
    fontWeight: "bold"
};

const descriptionStyle = {
    textAlign: 'center',
    fontSize: '2rem',
    color: '#736C6C'
};

const descriptionStyleMobile = {
    textAlign: 'center',
    fontSize: '1.5rem',
    color: '#736C6C'
};

const arrowDownStyle = {
    height: '3rem',
    justifySelf: 'center',
    alignSelf: 'center',
    gridColumn: '1/3'
};

// PropTypes
AD_DisplayPage.propTypes = {
    adPage: PropTypes.object.isRequired,
    arrowDownFunction: PropTypes.func.isRequired
};

export default AD_DisplayPage;