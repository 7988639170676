import React, {Component} from 'react';
import AD_FormsPage from "./sub-components/dashboard/AD_FormsPage";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import PDFIcon from '@material-ui/icons/InsertDriveFile';
import SettingsIcon from '@material-ui/icons/Settings';
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import AD_SettingsPage from "./sub-components/dashboard/AD_SettingsPage";



class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.getUserProfile().then(function () {

        })
    }

    componentDidMount() {

        // Initial media match
        this.setState({isMobile: window.matchMedia("(max-width: 850px)").matches});

        window.onresize = () => {
            // Subsequent  media matches on resize
            this.setState({isMobile: window.matchMedia("(max-width: 850px)").matches})
        }
    }

    state = {
        selectedIndex: 0,
        isOpen: false,
        isMobile: false,

        userWebProfile: {}
    };

    getUserProfile = async () => {

        let response = await fetch('https://cprforms.com/api/v1/users/webprofile/' + this.props.location.state.username, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.props.location.state.authToken
            }
        });

        if (response.ok){
            let respObject = await response.json();
            respObject.updateCallback = this.getUserProfile

            this.setState({userWebProfile: respObject})

        } else {
            alert("There appears to be a connection error, please try to login again later.")
        }

        this.forceUpdate()
    }

    changePage = (newPageListIndex) => {
        this.setState({
            selectedIndex: newPageListIndex,
            isOpen: false
        })
    };

    toggleDrawer = (open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({isOpen: open});
    };

    render() {

        // If there is no auth-token, redirect to home page
        if (!this.props.location.state) {
            window.location.href = "https://cprforms.com"
        }

        return (
            <div style={this.state.isMobile ? dashboardContainerMobile : dashboardContainer}>

                {/* Navigation bar, will only show if mobile  */}
                {this.state.isMobile &&
                    <MuiThemeProvider theme={theme}>
                        <AppBar position="static" elevation={2} color={"primary"}>
                            <Toolbar>
                                {/* TODO: Fix color issue when coming back from settings to forms*/}
                                <IconButton color={"secondary"} edge="start"  aria-label="menu" onClick={() => this.setState({isOpen: true})}>
                                    <MenuIcon/>
                                </IconButton>

                                <Typography variant="h6" >
                                    Toolkit Dashboard
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </MuiThemeProvider>
                }

                {/* Desktop version of side menu*/}
                {!this.state.isMobile &&

                <div id={"sideMenu"} style={sideMenu}>
                    <img src="assets/ToolKit_Compact.svg" alt="Toolkit Compact Logo" style={toolkitMenuLogo}/>
                    <Divider/>
                    <List style={menuItemsContainer} id={"menuItemsContainer"} >
                        <ListItem
                            button
                            selected={this.state.selectedIndex === 0}
                            onClick={event => this.changePage( 0)}
                        >
                            <ListItemIcon>
                                <PDFIcon
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary="Forms"
                                style={{fontWeight: "bold"}}

                            />
                        </ListItem>

                        <ListItem
                            button
                            selected={this.state.selectedIndex === 1}
                            onClick={() => this.changePage(1)}
                        >
                            <ListItemIcon>
                                <SettingsIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary="Settings"
                                style={{fontWeight: "bold"}}

                            />
                        </ListItem>

                    </List>
                </div>
                }

                {/* Mobile version of side menu*/}
                {this.state.isMobile &&

                    <SwipeableDrawer
                        open={this.state.isOpen}
                        onClose={this.toggleDrawer(false)}
                        onOpen={this.toggleDrawer(true)}
                    >
                        <img src="assets/ToolKit_Compact.svg" alt="Toolkit Compact Logo" style={toolkitMenuLogo}/>
                        <Divider/>
                        <List style={menuItemsContainer} id={"menuItemsContainer"}>
                            <ListItem
                                button
                                selected={this.state.selectedIndex === 0}
                                onClick={() => this.changePage(0)}
                            >
                                <ListItemIcon>
                                    <PDFIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary="Forms"
                                    style={{fontWeight: "bold"}}

                                />
                            </ListItem>

                            <ListItem
                                button
                                selected={this.state.selectedIndex === 1}
                                onClick={() => this.changePage(1)}
                            >
                                <ListItemIcon>
                                    <SettingsIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary="Settings"
                                    style={{fontWeight: "bold"}}

                                />
                            </ListItem>

                        </List>
                    </SwipeableDrawer>
                }

                {/*if "MyForms" selected show AD_FormPage*/}
                {this.state.selectedIndex === 0 && <AD_FormsPage username={this.props.location.state.username} token={this.props.location.state.authToken}/>}
                {this.state.selectedIndex === 1 && <AD_SettingsPage username={this.props.location.state.username} token={this.props.location.state.authToken} userWebProfile={this.state.userWebProfile} />}

            </div>
        );
    }
}

const dashboardContainer = {
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box"
};

const dashboardContainerMobile = {
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box"
};

const sideMenu = {
    height: "100%",
    width: "220px",
    borderRight: "solid 1px gainsboro",
    backgroundColor: "white"
};

let toolkitMenuLogo = {
    height: "120px",
    width: "100%",
    padding: "10%"
};

let menuItemsContainer = {
    paddingTop: "20%"
};

const theme = createMuiTheme({
    palette: {
        primary: { 500: "#2180CE" },
        secondary: {A400: "#FFFFFF"}
    },
    typography: { useNextVariants: true },
});


export default Dashboard;
