import React, {Component} from 'react';

import loginImg from "../../src-assets/TL-ResponsiveMockup-nomerge.png"

// JavaScript modulo does not work the same for negative nums, but this implementation does
function mod(n, m) {
    return ((n % m) + m) % m;
}

// TODO: Create animation when image changes
// TODO: Emulate touch with mouse or listen for mouse swipe gestures
class AD_Showcase extends Component {
    _isMounted = false;

    // Starts auto scrolling the images
    componentDidMount() {
        this._isMounted = true;
        this.timerID = setInterval(
            () => this.tick(),
            5000
        );
    }

    // Changes the by an increment of 1
    tick() {
        if (this._isMounted){
            this.setState({currentIndex: (this.state.currentIndex + 1) % this.state.images.length});
        }
    }

    // Ends auto scrolling the images once component un-mounts
    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.timerID);
    }

    // Swipe gesture settings
    startSlideXPos = 0;
    touchEnded = false;
    slideTriggerDelta = 100;

    state = {
        currentIndex: 0,
        autoSlide: true,
        images: [
            loginImg,
        ]
    };

    handleTouchStart = (e) => {
        this.startSlideXPos = e.targetTouches[0].clientX
    };

    handleTouchMove = (e) => {

        let delta = this.startSlideXPos - e.targetTouches[0].clientX;
        if(!this.touchEnded && delta > this.slideTriggerDelta){
            this.touchEnded = true;
            this.setState({currentIndex: (this.state.currentIndex + 1) % this.state.images.length});
            clearInterval(this.timerID);
        }

        else if (!this.touchEnded && delta < this.slideTriggerDelta * -1){
            this.touchEnded = true;
            this.setState({currentIndex: mod(this.state.currentIndex - 1, this.state.images.length)});
            clearInterval(this.timerID);
        }
    };

    handleTouchEnd = (e) => {
        this.touchEnded = false;
        this.startSlideXPos = 0;
    };

    changeImage(dotClicked) {

        // Stop the auto switching if user changes image manually
        clearInterval(this.timerID);
        this.setState({currentIndex: parseInt(dotClicked.getAttribute('data-key'))})
    }

    render() {

        let showCaseContainerCSS =  window.matchMedia("(max-width: 850px)").matches ?  showcaseContainerStyleMobile : showcaseContainerStyle;

        return (
            <div id={"main-showcase-container"} style={showCaseContainerCSS}>
                <img src={this.state.images[this.state.currentIndex]}
                     style={imageCSS}
                     onTouchStart={event => this.handleTouchStart(event)}
                     onTouchMove={event => this.handleTouchMove(event)}
                     onTouchEnd={event => this.handleTouchEnd(event)}
                     alt="iPad App Preview"/>
                <div id={"dots-container"} style={dotsContainerCSS}>

                    {
                        this.state.images.map( (value, index) => {
                            return <div key={index}
                                        data-key={index}
                                        style={index === this.state.currentIndex ? selectedDotCSS : unselectedDotCSS}
                                        onClick={evt => this.changeImage(evt.target)}/>
                        })
                    }


                </div>

            </div>
        );
    }
}

const showcaseContainerStyle = {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
};

const showcaseContainerStyleMobile = {
    height: "100%",
    width: "60%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
};

const imageCSS = {
    justifySelf: 'center',
    alignSelf: 'center',
    maxHeight: '75vh',
    maxWidth: '100%',
};

const dotsContainerCSS = {
    marginTop: "10px",
    height: "14px",
    maxWidth: "90%",
    display: "grid",
    gridTemplateColumns: "auto auto auto auto auto",
    columnGap: "8px",
    alignItems: "center",
    textAlign: "center",
    justifyItems: "center",
};

const selectedDotCSS = {
    height: "14px",
    width: "14px",
    border:" 2px solid transparent",
    borderRadius: "50%",
    backgroundColor: "gray",
    cursor: "pointer",
    transition:" all .4s ease"

};

const unselectedDotCSS = {
    height: "10px",
    width: "10px",
    border:" 2px solid transparent",
    borderRadius: "50%",
    backgroundColor: "lightgray",
    cursor: "pointer",
    transition:" all .4s ease"
};


AD_Showcase.propTypes = {};

export default AD_Showcase;