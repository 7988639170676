import React, {Component} from 'react';
import {PulseLoader, SyncLoader} from "react-spinners";
import { css } from '@emotion/react';
import TextField from "@material-ui/core/TextField";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import NavigationBar from "./NavigationBar";


class Contact extends Component {

    state = {
        name: "",
        email:"",
        message:"",
        isProcessingRequest: false,

        nameError: false,
        nameHelperText: "",

        emailError: false,
        emailHelperText: "",

        messageError: "",
        messageHelperText: ""
    };

    overlayCSS = {
        position: "absolute",
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgba(0,0,0,.5)",
        visibility: "hidden"
    };

    sendMessage = async (e) => {

        //this.overlayCSS["visibility"] = "visible";

        // Starts the loading spinner
        this.setState({isLoading: true});

        let response = await fetch('https://cprforms.com/api/v1/emailus', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },

            body: JSON.stringify({
                name: this.state.name,
                email: this.state.email,
                message: this.state.message
            })
        });

       //  this.setState({isLoading: false});
        if (response.ok){
            alert("Message Sent!");
        } else {
            alert("There was an error sending your message. Please try again later.")
        }

    };

    render() {

        const isMobile = window.matchMedia("(max-width: 1025px)").matches;

        return (
            <div >
                <NavigationBar/>

                <div style={contentContainerCSS} id={"contentContainer"}>

                    <div style={contactInfoCSS}></div>

                    <div style={contactCardCSS} id={"contactCard"}>
                        <h2 style={{color: "#2180CE"}}>Contact Us</h2>

                        <MuiThemeProvider theme={theme}>
                            <TextField style={contactInputContainer}
                                       error={this.state.nameError}
                                       helperText={this.state.nameHelperText}
                                       label="Name"
                                       variant={"outlined"}
                                       type={"text"}
                                       onChange={evt => this.setState({name: evt.target.value})}
                                       onFocus={() => this.setState({nameError: false, nameHelperText: ''})}
                            />

                            <TextField style={contactInputContainer}
                                       error={this.state.emailError}
                                       helperText={this.state.emailHelperText}
                                       label="Email"
                                       variant={"outlined"}
                                       type={"text"}
                                       onChange={evt => this.setState({email: evt.target.value})}
                                       onFocus={() => this.setState({emailError: false, emailHelperText: ''})}
                            />

                            <TextField
                                style={messageInputCSS}
                                error={this.state.messageError}
                                helperText={this.state.messageHelperText}
                                multiline
                                label="Message"
                                rows="10"
                                variant="outlined"
                                onChange={evt => this.setState({message: evt.target.value})}
                            />

                            <Button
                                disabled={this.state.isProcessingRequest}
                                variant="contained"
                                color="primary"
                                size="large"
                                style={sendButtonCSS}
                                onClick={this.sendMessage}
                            >
                                Send
                                <PulseLoader
                                    css={overrideLoading}
                                    sizeUnit={"px"}
                                    size={isMobile ? 9 : 12}
                                    color={'#FFFFFF'}
                                    loading={this.state.isProcessingRequest}
                                />
                            </Button>
                        </MuiThemeProvider>
                    </div>
                </div>
            </div>
        );
    }
}

const theme = createMuiTheme({
    palette: {
    primary: { 500: "#2180CE" },
},
    typography: { useNextVariants: true },
});


let contentContainerCSS = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 4rem)",
    width: "100vw"
};

let contactInfoCSS = {
    width: "0%",
    height: "80vh",
};

let contactCardCSS = {
    width: "100%",
    height: "80vh",

    display: 'grid',
    gridTemplateColumns: '1fr',
    textAlign: 'center',
    alignContent: 'center',
    justifyItems: 'center',
};

let contactInputContainer = {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    marginBottom: "2%"
};

let messageInputCSS = {
    width: "80%",
    marginBottom: "2%"

};

let sendButtonCSS = {
    position: "relative",
    width: "40%",
    color: "#FFFFFF",
    marginTop: "6px"
};

const overrideLoading = css`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2180CE;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;


export default Contact;
