import React, { Component } from 'react';
import './App.css';
import {BrowserRouter as Router, Route, HashRouter} from "react-router-dom";
import Home from './components/Home';
import Registration from './components/Registration';
import Login from './components/Login';
import Contact from './components/Contact'
import Terms from './components/Terms'
import Dashboard from "./components/Dashboard";
import PasswordRecovery from "./components/PasswordRecovery";
import Questions from "./components/Questions";
import AD_RegistrationCompleted from "./components/sub-components/AD_RegistrationCompleted";
import {Helmet} from 'react-helmet';



// TODO: Figure out vertical scaling | TEST WITH MAC
class App extends Component {
  render() {
    return (
        <HashRouter>
          <div className="root-content">
            <Helmet>
                <title>CPRforms</title>
                <meta name="description" content="TK App"/>
            </Helmet>
            <Route exact path="/" component={Home}/>
            <Route path="/registration" component={Registration}/>
            <Route path="/login" component={Login} />
            <Route path="/dashboard" component={Dashboard}/>
            <Route path="/contact" component={Contact}/>
            <Route path="/terms" component={Terms}/>
            <Route path="/questions" component={Questions}/>
            <Route path="/recovery" component={PasswordRecovery}/>
          </div>
        </HashRouter>
    );
  }
}

export default App;
