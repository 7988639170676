import React, {Component} from 'react';
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutline from "@material-ui/core/SvgIcon/SvgIcon";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import AD_FormListItem from "../AD_FormListItem";
import PropTypes from "prop-types";     
import IconButton from "@material-ui/core/IconButton";
import SortIcon from '@material-ui/icons/Sort';
import {BeatLoader} from "react-spinners";
import {css} from "@emotion/react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PrintIcon from '@material-ui/icons/Print';
import DownloadIcon from '@material-ui/icons/GetApp';
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import print from "print-js"
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

// Used to generate a key for the mapped values of state.forms
function hashCode(toHash) {
    let hash = 0;
    if (toHash.length === 0) {
        return hash;
    }
    for (let i = 0; i < toHash.length; i++) {
        let char = toHash.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash;
    }
    return hash;
}

class AD_FormsPage extends Component {

     listFetched = false;
    componentDidMount() {

        if (!this.listFetched){
            this.setState({isLoadingForms: true});
            this.fetchPDFList().then(r => this.setState({isLoadingForms: false}));
            this.listFetched = true;
        }
    }

    state = {
        selectedPDFIndex: -1,
        selectedPDFData: "",
        formsImmutable: [],
        forms: [],

        isLoadingForms: false,
        isLoadingPreview: false,
        sortingMenuAnchorElement: null,
        selectedSortingMenuIndex: 0,

        openDownloadTooltip: false
    };

    fetchPDFList = async() => {

        this.setState({isLoadingForms:true});
        let response = await fetch('https://cprforms.com/api/v1/filepaths/' + this.props.username, {
            method: "GET",
            headers: {
                "x-access-token": this.props.token
            },
        });

        if (response.ok){

            let respObject = await response.json();

            respObject.files.sort(function (a,b) {
                // Use regex "/([^/]+$)/" to match everything after the last "/" which will be the epoch time
                return parseInt(/([^/]+$)/.exec(a)[0]) > parseInt(/([^/]+$)/.exec(b)[0]) ? -1 : 1
            });

            await this.setState({forms: respObject.files});
            await this.setState({formsImmutable: respObject.files});

        }

        else if (response.status === 401) {
            // TODO: Figure out why 401s here
        }

        else {
            alert("A connection error occurred, please try getting forms again later.")
        }

        this.setState({isLoadingForms:false});
    };

    downloadPDF = async(pdfURI) => {

        this.setState({isLoadingPreview:true});
        let response = await fetch('https://cprforms.com/api/v1/download/' + pdfURI.replace("./UserPDF/",""), {
            method: "GET",
            headers: {
                "x-access-token": this.props.token
            },
        });

        if (response.ok){

            let respObject = await response.arrayBuffer();
            let blob = new Blob([respObject], {type:"application/pdf"});
            const fileURL = URL.createObjectURL(blob);

            this.setState({selectedPDFData: fileURL + "#toolbar=0&navpanes=0"});

            if (window.matchMedia("(max-width: 1025px)").matches){
                window.open(fileURL)
            }

        } else {
            this.setState({selectedPDFData: ""});
            alert("Invalid PDF URI")
        }

        this.setState({isLoadingPreview:false});
    };

    pdfSearchFilterHelper = (URI, query) => {
        let parsedURI = URI.split("/");
        return parsedURI[parsedURI.length - 2].includes(query);
    };

    handleSearchInput = (evt) => {
        let query = evt.target.value;

        if (query.length > 0){
            this.setState({
                selectedPDFIndex: -1,
                forms: this.state.forms.filter((URI) => this.pdfSearchFilterHelper(URI, query))
            })
        } else {
            this.setState({
                selectedPDFIndex: -1,
                forms: this.state.formsImmutable
            })
        }

    };

    selectItemHandler = (newIndex, pdfURI) => {

        // Check that the newly  selected pdf isn't the same previously selected pdf
        if (newIndex !== this.state.selectedPDFIndex){
            this.setState({selectedPDFIndex: newIndex});
            this.downloadPDF(pdfURI).then(r => console.log("Done downloading"))

        } else {
            // if it is the same, deselect by reverting state to default values
            this.setState({selectedPDFIndex: -1});
            this.setState({selectedPDFData: ""});
        }
    };

    openSortingMenu = (event) => {
        this.setState({sortingMenuAnchorElement: event.currentTarget});
    };

    handleSortingMenuItemClick = (newlySelectedSortingIndex) => {
        this.setState({
            forms: newlySelectedSortingIndex !== this.state.selectedSortingMenuIndex ? this.state.forms.reverse() : this.state.forms,
            selectedPDFIndex: newlySelectedSortingIndex !== this.state.selectedSortingMenuIndex ? -1 : this.state.selectedPDFIndex,
            sortingMenuAnchorElement: null,
            selectedSortingMenuIndex: newlySelectedSortingIndex
        })
    };

    closeSortingMenu = (event) => {
        this.setState({sortingMenuAnchorElement: null});

    };

    toolbarDownload = () => {
        this.setState({openDownloadTooltip: true})
    }

    toolbarPrint = () => {
        print(this.state.selectedPDFData)
    }

    handleDownloadTooltipClose = () => {
        this.setState({openDownloadTooltip: false})
    }

    render() {

        const isMobile = window.matchMedia("(max-width: 1025px)").matches;

        return (
            <div id={"formsPageContainer"} style={formsPage}>
                <h2 style={PageTitle}>Forms</h2>
                <TextField style={searchInput}
                           label="Search Forms..."
                           variant={"outlined"}
                           InputProps={{
                               endAdornment: <InputAdornment position="end">
                                   <Tooltip title={"The name of the training center you teach at"}>
                                       <HelpOutline color={"action"}/>
                                   </Tooltip>
                               </InputAdornment>,
                           }}
                           onChange={this.handleSearchInput}
                />

                <Paper style={isMobile ? formsContainerMobile : formsContainer} id={"formsContainer"}>

                    <div style={leftSideContainer} id={"leftSideContainer"}>

                        <div style={leftSideToolbar} id={"leftSideToolbar"}>
                            <h3 style={toolbarTitle} id={"toolbarTitle"}>All Forms</h3>

                            <div id={"toolbarButtonContainer"} style={toolbarButtonContainer}>
                                <IconButton  onClick={this.openSortingMenu}>
                                    <SortIcon />
                                </IconButton>

                                <Menu
                                    id="sortingMenu"
                                    anchorEl={this.state.sortingMenuAnchorElement}
                                    keepMounted
                                    open={Boolean(this.state.sortingMenuAnchorElement)}
                                    onClose={this.closeSortingMenu}
                                >
                                    <MenuItem
                                        selected={0 === this.state.selectedSortingMenuIndex}
                                        onClick={() => this.handleSortingMenuItemClick(0)}
                                    >
                                        Date Uploaded: Newest
                                    </MenuItem>

                                    <MenuItem
                                        selected={1 === this.state.selectedSortingMenuIndex}
                                        onClick={() => this.handleSortingMenuItemClick(1)}
                                    >
                                        Date Uploaded: Oldest
                                    </MenuItem>
                                </Menu>
                            </div>

                        </div>

                        <List id={"formsList"} style={formList}>
                            <BeatLoader
                                css={overrideLoading}
                                sizeUnit={"px"}
                                size={20}
                                color={'#2180CE'}
                                loading={this.state.isLoadingForms}
                            />
                            {this.state.forms.map((form, i) => <AD_FormListItem key={hashCode(form)} pdfURI={form} index={i} currentlySelectedIndex={this.state.selectedPDFIndex} selectedHandler={this.selectItemHandler} />)}
                        </List>
                    </div>


                    {!isMobile &&
                        <div id={"pdfPreview"} style={pdfPreview}>
                            <BeatLoader
                                css={overrideLoading}
                                sizeUnit={"px"}
                                size={20}
                                color={'#2180CE'}
                                loading={this.state.isLoadingPreview}
                            />

                            {/* Show image when PDF not selected */}
                            {this.state.selectedPDFIndex < 0 && <div id={"noPDFSelectedContainer"} style={noPDFSelectedContainer}>

                                <img src="assets/pdf_icon.svg" style={{height:"40px", width:"auto", marginBottom: "5px"}} alt="pdf picture"/>
                                <p>Select PDF to preview</p>

                            </div>}

                            {/* Show preview when PDF is selected */}
                            {this.state.selectedPDFIndex >= 0 &&
                                <div style={pdfToolbarContainer}>
                                    <div style={pdfToolbar}>

                                        {/*TODO: Change to Popover*/}
                                        <MuiThemeProvider theme={theme}>
                                            <ClickAwayListener onClickAway={this.handleDownloadTooltipClose}>
                                                <Tooltip
                                                    arrow
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    onClose={this.handleDownloadTooltipClose}
                                                    open={this.state.openDownloadTooltip}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title="To download this PDF, press the 'print' button to the right and select 'Save as PDF' as the Destination"
                                                >
                                                    <IconButton aria-label="print" color={"primary"} onClick={this.toolbarDownload}>
                                                        <DownloadIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </ClickAwayListener>

                                            <IconButton aria-label="print" color={"primary"}  onClick={this.toolbarPrint}>
                                                <PrintIcon/>
                                            </IconButton>
                                        </MuiThemeProvider>
                                    </div>
                                    <iframe title={"PDF Preview"} src={this.state.selectedPDFData} frameBorder="0" style={{height: "90%", width: "100%", border:"none"}}/>

                                    {/* TODO: <embed src={"https://drive.google.com/viewerng/viewer?embedded=true&url=" + this.state.selectedPDFData} style={{height: "90%", width: "100%", border:"none"}}/>*/}
                                </div>


                            }

                        </div>
                    }
                </Paper>
            </div>
        );
    }
}

const PageTitle = {
    marginBottom: "2%",
    fontWeight: "bold"

};

const formsPage = {
    height: "100%",
    width: "100%",
    padding: "20px",
    backgroundColor: "ghostwhite"
};

const searchInput = {
    height: "auto",
    width: "100%",
    marginBottom: "2%",
    backgroundColor: "white"
};

const formsContainer = {
    display: "grid",
    height: "80%",
    width:" 100%",
    gridTemplateColumns: "1fr 2fr",
};

const formsContainerMobile = {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
    height: "50%",
    width:" 100%"
};

const leftSideContainer = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "auto"
};

const leftSideToolbar = {
    height: "auto",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRight: "solid 1px gainsboro",
    borderBottom: "solid 2px gainsboro",
    paddingLeft: "16px",
    paddingRight: "16px"
};

const toolbarTitle = {
    justifySelf: "left",
    margin: "0px"

};

// TODO: For future implementation where the user will be able to send or download multiple files
const toolbarButtonContainer = {
    height: "100%",
    width: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
};

const formList = {
    height: "90%",
    width: "100%",
    borderRight: "solid 1px gainsboro",
    overflow: "auto"
};

const pdfToolbarContainer = {
    height: "100%",
    width: "100%",
    border:"none",
    backgroundColor: "#323639",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 10px 2px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    padding: "0 30px"
}

const pdfToolbar = {
    height: "10%",
    width: "100%",

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#323639"
}

const pdfPreview = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
};

const noPDFSelectedContainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "gray"
};


const overrideLoading = css`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

const theme = createMuiTheme({
    palette: {
        primary: { 500: "#FFFFFF" },
        secondary: {A400: "#C62828"}
    },
});

AD_FormsPage.propTypes = {
    token: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired
};

export default AD_FormsPage;
