import React, {Component} from 'react';
import { Column, Row} from 'simple-flexbox';
import Icon from "@material-ui/core/Icon";
import AddIcon from '@material-ui/icons/Add';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import CreateIcon from '@material-ui/icons/Create';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DescriptionIcon from '@material-ui/icons/Description';
import MoreVertIcon from '@material-ui/icons/MoreVert';



class Questions extends Component {

    state = {

    };


    render() {
        return (
    <Column flexGrow={1}>
        <Row wrap horizontal="spaced">
          <Column
            style={{
              backgroundColor: "",
              minWidth: 250,
              maxWidth: "100%",
              padding: 12,
              color: ""
            }}
            flexGrow={1}
            horizontal="center"
          >
            <h3 style={{color: "#2180CE"}}> Questions </h3>
            <p>
Below you find some answers to frequently asked questions about the App.</p>
<p style={QuestionsCSS}>I made an account...Now what?</p>

<p style={textCSS}>First thing to do after making an account on the website is to go to the Dashboard homepage and login with your username and password - <a href="https://cprforms.com/app/" target="_blank">Link Here!</a> </p>
<span style={textCSS}>Once you get signed in with the account you made you can start setting up your information. You will first want to click on "Settings" button on the left side of the page and fill out your Instructor ID and Instructor Card Exp Date, hit the <Icon style={{color: "#2180CE"}}><CreateIcon/></Icon> top right and edit the fields, when you are done hit SAVE. You will also want to set up your signature at this time too. Hit "Signature" and sign your name, then hit save. Now you are ready to make your first class. </span>

<p style={QuestionsCSS}>How do I make a class?</p>
<p style={textCSS}> To make a class in InstructorTK you first click Classes on the top left of the screen. Now look for the <Icon style={{color: "#2180CE"}}><AddCircleIcon/></Icon> at the bottom of the screen and fill out all the information for your class - hit Save when you are done!</p>

<p style={QuestionsCSS}>How do I add students to the class?</p>
<p style={textCSS}> First find your class by date on the calendar and click the day, the classes for that day should then be listed below the calendar. Click on the class box below the calendar and you should see the class to the right if on desktop or on a new screen if you are on mobile. You then hit the <Icon style={{color: "#2180CE"}}><AddCircleIcon/></Icon> in the bottom right to add a student.</p>

<p style={QuestionsCSS}>Oops...I misspelled a student or some class info is wrong...how do I fix it?</p>
<p style={textCSS}>To fix a student object you need to go to the class screen for the class that student is in, click on the student and you can then Edit or Delete the student from this screen. </p>
<p style={textCSS}>To edit a class object you will select the class from the calendar to get to the class/student view. Then click on the <Icon style={{color: "#2180CE"}}><CreateIcon/></Icon> in the top right - this will bring up the class screen. Edit any values you want to change and hit Save.</p>

<p style={QuestionsCSS}>How do I generate my class documents?</p>
<p style={textCSS}>At the bottom of the class/student screen select <Icon style={{color: "#2180CE"}}><DescriptionIcon/></Icon> - This will take you to the generate forms screen.  Once there, select each form you want generated for the students and press "Generate" at the bottom of the screen.</p>

<p style={QuestionsCSS}>My class forms are generated, now what?</p>
<p style={textCSS}>Now you can sit back and relax. We just saved you like...a million minutes filling out paperwork :)</p>
<p style={textCSS}>In the top right of the form screen you can hover your mouse for the option to download, print or save your forms. We automatically upload your forms for you to retrieve also. You can find these by clicking "Forms" at the left side of the page if your on the desktop, or the bottom of the screen if you are on mobile. </p>

<p style={QuestionsCSS}>How do I create the Rapid eCards?</p>
<p style={textCSS}>On the class/student screen click the <Icon style={{color: "#2180CE"}}><MoreVertIcon/></Icon> and select eCard CSV. It will download for you.</p>

<p style={QuestionsCSS}>How do I delete a class?</p>
<p style={textCSS}>On the class/student screen click the <Icon style={{color: "#2180CE"}}><MoreVertIcon/></Icon> and select "Delete Class". This is irreversible!</p>


<p style={textCSS}> Have a Question we didn't answer or some constructive feedback? <a href="https://cprforms.com/contact">Drop us a line.</a> </p>
          </Column>
        </Row>
      </Column>
        );
    }
}

let selectorCSS = {
    fontSize: "28px",
    padding: "0 10% 0 10%", fontWeight: "bold",
    color: "#2180CE"
};


let rotate1 = {
    color: "#2180CE",
    display: "inline-block",
    transform:"rotate(180deg) translate(0, -10px)"
}


let QuestionsCSS = {
    fontSize: "22px",
    padding: "0 30% 0 30%", fontWeight: "bold",
    color: "#2180CE",
    align: "left"
};

let textCSS = {
    fontSize: "14px",
    padding: "0 30% 0 30%", fontWeight: "normal",
    color: "#000000",
    display: "inline-block",
};

let pageContainerCSS = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 4rem)",
    width: "100vw"
};

let selectorContainerCSS = {
    marginTop: "2%",
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
};

let textContainerCSS = {
    marginTop: "2%",
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
};

export default Questions;
