import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {PulseLoader} from "react-spinners";
import {css} from "@emotion/react";

class CancelSub extends Component {


    state = {
        isProcessingCancel: false,
        openCancelSubDialog: false
    }

    cancelSubscription = async () => {

        let snackbarText;
        let snackBarStyle;

        this.setState({isProcessingCancel:true});
        try {
            let response = await fetch('https://cprforms.com/api/v1/users/' + this.props.username + "/cancelsub", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": this.props.token
                },
            });

            if (response.ok) {
                snackbarText = "Your subscription was cancelled successfully"
                snackBarStyle = "#4caf50"

            }

            else if (response.status === 304) {
                snackbarText = "Your subscription has already been cancelled"
                snackBarStyle = "#ff9801"
            }

            else {
                alert("There was an error canceling your subscription, please try again later or contact support")
                snackbarText =  "There was an error canceling your subscription, please try again later or contact support"
                snackBarStyle = "#f14335"
            }
        } catch (e) {
            snackbarText =  "There was an error canceling your subscription, please try again later or contact support"
            snackBarStyle = "#f14335"
        }

        // Display a snackbar in AD_SettingsPage
        this.props.displaySnack({
            openSnackbar: true,
            snackbarText: snackbarText,
            snackbarStyle: snackBarStyle
        })

        this.setState({
            isProcessingCancel: false,
            openCancelSubDialog: false
        })
    }

    handleCloseCancelDialog = () => {
        this.setState({openCancelSubDialog: false})
    }

    render() {
        return (
            <div>
                <ListItem>
                    <ListItemText
                        primary={"Cancel Subscription"}
                        secondary={"You will retain access to all premium features until your next billing cycle"}
                    />
                    <div style={this.props.isMobile ? {height: "100%", width: "100%"} : {}}>
                        <ListItemSecondaryAction>
                            <Button color="secondary" onClick={()=> this.setState({openCancelSubDialog: true})}>Cancel</Button>
                        </ListItemSecondaryAction>
                    </div>
                </ListItem>

                <Dialog
                    open={this.state.openCancelSubDialog}
                    onClose={this.handleCloseCancelDialog}
                    aria-labelledby="alert-dialog-cancel-sub"
                    aria-describedby="alert-dialog-cancel-sub-desc"
                >
                    <DialogTitle id="alert-dialog-title">{"Cancel Subscription?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Cancelling your subscription will revoke access to all premium features after your current billing period ends.
                            <br/>
                            <br/>
                            Your classes, files, and any other data will still be available if you re-subscribe.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseCancelDialog} color="primary">
                            Keep Plan
                        </Button>
                        <Button onClick={this.cancelSubscription} color="secondary">
                            Cancel Subscription
                            <PulseLoader
                                css={overrideLoading}
                                sizeUnit={"px"}
                                size={this.state.isMobile ? 6 : 9}
                                color={'#FFFFFF'}
                                loading={this.state.isProcessingCancel}
                            />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const overrideLoading = css`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 10px;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

CancelSub.propTypes = {
    username: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    isMobile:  PropTypes.bool.isRequired,
    displaySnack: PropTypes.func.isRequired
};

export default CancelSub;