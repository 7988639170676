import React, {Component} from 'react';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import PDFIcon from '@material-ui/icons/InsertDriveFile';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";

class AD_FormListItem extends Component {

    componentDidMount() {
        this.parseURItoName(this.props.pdfURI)
    }

    state = {
        pdfName: "",
        pdfEpoch: 0,
        pdfStandardTime: "",
    };

    parseURItoName = (serverURI) => {

        // URI is built like this: ./UserPDF/<USERNAME>/<PDF_NAME>/<EPOCH TIME>
        let parsedData = serverURI.split("/");

        this.setState({pdfName: parsedData[parsedData.length - 2] + ".pdf"});
        this.setState({pdfEpoch: parseInt(parsedData[parsedData.length - 1])});

        // Convert epoch to human time format: MM/DD/YYYY at HH:mm
        let standardDateTime = new Date(parseInt(parsedData[parsedData.length - 1]));

        let date =( standardDateTime.getMonth() + 1) + "/" +  standardDateTime.getDate() + "/" + standardDateTime.getFullYear();
        let time = standardDateTime.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
        this.setState({pdfStandardTime: date + " at " + time});

    };

    render() {
        return (
            <React.Fragment>
                <ListItem button
                          selected={this.props.index === this.props.currentlySelectedIndex}
                          alignItems="flex-start"
                          onClick={() => this.props.selectedHandler(this.props.index, this.props.pdfURI)}>
                    <ListItemAvatar>
                        <Avatar>
                            <PDFIcon />
                        </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                        primary={this.state.pdfName}
                        secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="textPrimary"
                                >
                                </Typography>
                                {"Uploaded: " + this.state.pdfStandardTime}
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <Divider />
            </React.Fragment>
        );
    }
}

AD_FormListItem.propTypes = {
    pdfURI: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    currentlySelectedIndex: PropTypes.number.isRequired,
    selectedHandler: PropTypes.func.isRequired,
};
export default AD_FormListItem;