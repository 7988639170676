import React, {Component} from 'react';
import AD_RegistrationCard from './AD_RegistrationCard';
import {Elements, StripeProvider} from 'react-stripe-elements';
import AD_RegistrationInformationCard from "./AD_RegistrationInformationCard";
import AD_PaymentCard from "./AD_PaymentCard";
import PropTypes from 'prop-types'
import {PulseLoader} from 'react-spinners';
import { css } from '@emotion/react';
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from '@material-ui/icons/ArrowBack';
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import AD_RegistrationCompleted from "./AD_RegistrationCompleted";
import AD_VerificationCode from "./AD_VerificationCode";


const stages = {
    INTRODUCTION: 0,
    ADMIN: 1,
    INSTRUCTOR: 2,
    INFORMATION: 3,
    VERIFICATION: 4,
    PAYMENT: 5,
    COMPLETED: 6,
};

const planTypes = {
    INSTRUCTOR_FREE: 0,
    INSTRUCTOR_PREMIUM: 1,
    ADMIN_BASIC: 2,
    ADMIN_PREMIUM: 3
};

class AD_RegistrationContainer extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    createDataForInformationTable(feature, availability) {
        return {feature, availability };
    }

    adminProcess = () => {
        this.setState({processStage: stages.ADMIN});
        this.props.titleFunction(stages.ADMIN);
    };

    instructorProcess = () => {
        this.setState({processStage: stages.INSTRUCTOR});
        this.props.titleFunction(stages.INSTRUCTOR);

    };

    updateUserInfo = (newInfo) => {
        this.setState({
            userInformation: {
                username: newInfo.username,
                email: newInfo.email,
                planType: this.state.userInformation.planType
            }
        });
    };

    // Toggles loading animation
    toggleLoading = () => {
        this.setState({isLoading: !this.state.isLoading})
    };

    handlePlanChange = (newPlanType) => {
        this.setState({userInformation: {planType: newPlanType}});
        window.scrollTo(0, 0)
    };

    informationProcess = () => {
        this.setState({processStage: stages.INFORMATION});
        this.props.titleFunction(stages.INFORMATION);
        window.scrollTo(0, 0)
    };

    verificationProcess = () => {
        this.setState({processStage: stages.VERIFICATION});
        this.props.titleFunction(stages.VERIFICATION);
        window.scrollTo(0, 0)
    };

    paymentProcess = () => {
        this.setState({processStage: stages.PAYMENT});
        this.props.titleFunction(stages.PAYMENT);
        window.scrollTo(0, 0)
    };

    completedProcess = () => {
        this.setState({processStage: stages.COMPLETED});
        this.props.titleFunction(stages.COMPLETED);
        window.scrollTo(0, 0)
    };

    // Handles back button
    // TODO: Fix this, back button currently just goes back to beginning
    goBack = () => {
        switch (this.state.processStage) {
            case 1:
            case 2:
                this.setState({processStage: stages.INTRODUCTION});
                this.props.titleFunction(stages.INTRODUCTION);
                break;
            default:
                this.setState({processStage: stages.INTRODUCTION});
                this.props.titleFunction(stages.INTRODUCTION);
                break;
        }
    };

    state = {
        processStage: stages.INTRODUCTION,
        isLoading: true,

        userInformation: {
            username: '',
            email: '',
            planType: ''
        },

        headers: {
            phaseOneHeader: "I am a(n):",
            phaseTwoHeaderAdmin: "Admin Plans",
            phaseTwoHeaderInstructor: "Instructor Plans"
        },

        introCards: [
            {
                id: 0,
                title: "Center Administrator",
                image: "",
                price: "(Coming Soon)",
                onClick: this.adminProcess,
                buttonText: "Join",
                informationTableRows: [
                    this.createDataForInformationTable('Student/Form Master List', "✓"),
                    this.createDataForInformationTable('Audit Trailing', "✓"),
                ]
            },

            {
                id: 1,
                title: "Instructor",
                image: "",
                price: "FREE",
                onClick: this.instructorProcess,
                buttonText: "Join",
                informationTableRows: []
            },
        ],

        adminPlanCards: [
            {
                id: 0,
                title: "Basic",
                image: "",
                price: "$199/yr",
                onClick: this.adminProcess,
                buttonText: "Select",
                planType: planTypes.ADMIN_BASIC,
                informationTableRows: []
            },

            // {
            //     id: 1,
            //     title: "Premium",
            //     image: "",
            //     price: "$250/yr",
            //     onClick: this.adminProcess,
            //     buttonText: "Select",
            //     planType: planTypes.ADMIN_PREMIUM,
            //     informationTableRows: []
            // },
        ],

        instructorPlanCards: [
            {
                id: 0,
                title: "Basic",
                image: "assets/instructor_basic.svg",
                price: "$24.99/yr after 14-Day Trial",
                onClick: this.informationProcess,
                buttonText: "Select",
                planType: planTypes.INSTRUCTOR_PREMIUM,
                informationTableRows: [
                    this.createDataForInformationTable('Web Dashboard Access', "✓"),
                    this.createDataForInformationTable('Course & Student Management', "✓"),
                    this.createDataForInformationTable('Auto-Generate Forms', "✓"),
                    this.createDataForInformationTable('Offline Use', "✓"),
                    this.createDataForInformationTable('Mass Student Email', ""),
                    this.createDataForInformationTable('Unlimited Form Storage', ""),
                ]
            },

            // {
            //     id: 1,
            //     title: "Premium",
            //     image: "assets/instructor_premium.svg",
            //     price: "(Coming Soon)",
            //     onClick: this.informationProcess,
            //     buttonText: "Select",
            //     planType: planTypes.INSTRUCTOR_PREMIUM,
            //     informationTableRows: [
            //         this.createDataForInformationTable('Web Dashboard Access', "✓"),
            //         this.createDataForInformationTable('Course & Student Management', "✓"),
            //         this.createDataForInformationTable('Auto-Generate Forms', "✓"),
            //         this.createDataForInformationTable('Offline Use', "✓"),
            //         this.createDataForInformationTable('Mass Student Email', "✓"),
            //         this.createDataForInformationTable('Unlimited Form Storage', "✓"),
            //     ]
            // }
        ]
    };

    render() {
        let cards;

        // Sets which cards should be displayed depending on the registration process
        switch (this.state.processStage) {
            case stages.INTRODUCTION:
                cards = this.state.introCards;
                break;
            case stages.ADMIN:
                cards = this.state.adminPlanCards;
                break;
            case stages.INSTRUCTOR:
                cards = this.state.instructorPlanCards;
                break;
            default:
                cards = this.state.introCards;
        }

        const isMobile = window.matchMedia("(max-width: 1025px)").matches;
        const backArrowCSS = isMobile ? backButtonStyleMobile : backButtonStyle;
        const registrationCSS = isMobile ? registrationContainerStyleMobile : registrationContainerStyle;

        // TODO: Left off adding loading button and making mobile compatible
        return (
            // Shows back button if process stage > 0
            <div style={registrationCSS} id={"registrationContainer"}>
                <PulseLoader
                    css={overrideLoading}
                    sizeUnit={"px"}
                    size={150}
                    color={'#2180CE'}
                    loading={this.state.isLoading}
                />
                {this.state.processStage > 0 && this.state.processStage < 4 &&
                <MuiThemeProvider theme={theme}>
                        <IconButton style={backArrowCSS} color={"primary"}  alt="back" onClick={this.goBack}>
                            <ArrowBack/>
                        </IconButton>
                </MuiThemeProvider>
                }

                    {this.state.processStage < stages.INFORMATION && cards.map((card) => <AD_RegistrationCard key={card.id} card={card} planSelected={this.handlePlanChange}/>)}
                    {this.state.processStage === stages.INFORMATION && <AD_RegistrationInformationCard changePageFunc={this.verificationProcess} updateUserInfo={this.updateUserInfo}/>}
                    {this.state.processStage === stages.VERIFICATION && <AD_VerificationCode username={this.state.userInformation.username} changePageFunc={this.paymentProcess}/>}
                    {this.state.processStage === stages.PAYMENT && <StripeProvider apiKey="pk_live_GWpgxirfxWtCkzoOjcc1qplH00id40Dfj9"><Elements><AD_PaymentCard changePageFunc={this.completedProcess} toggleLoadingScreen={this.toggleLoading} userInformation={this.state.userInformation} planChangeHandler={this.handlePlanChange}/></Elements></StripeProvider>}
                    {this.state.processStage === stages.COMPLETED && <AD_RegistrationCompleted/>}

            </div>
        );
    }
}

const theme = createMuiTheme({
    palette: {
        primary: { 500: "#2180CE" },
    },
});

const registrationContainerStyle = {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    gridAutoFlow: 'column',
    paddingTop: "20px",
    paddingBottom: "20px",
    maxWidth: '100%',
    height: '100%',
    gridColumnGap: '4vw',
    gridAutoColumns: 'auto',
    gridTemplateRows: '1fr',
};

const registrationContainerStyleMobile = {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    gridAutoFlow: 'row',
    paddingTop: "20px",
    paddingBottom: "20px",
    width: '100%',
    height: '100%',
    gridRowGap: '4vh',
    gridTemplateRows: '1fr',
};


const backButtonStyle = {
    position: "absolute",
    top: "5rem",
    left: "2rem",
    height: "40px",
    width: "40px"
};

const backButtonStyleMobile = {
    position: "absolute",
    top: "5rem",
    left: "1rem",
    height: "30px",
    width: "30px",
    marginBottom: '10px'
};

const overrideLoading = css`
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: none;
    border-radius: 10px;
`;

// PropTypes
AD_RegistrationContainer.propTypes = {
    titleFunction: PropTypes.func.isRequired
};

export default AD_RegistrationContainer;
