import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";
import {PulseLoader} from "react-spinners";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {css} from "@emotion/react";

class VerifyAccount extends Component {

    state = {
        openConfirmationDialog: false,

        isSendingUserCodeProcessing: false,
        isProcessingConfirmation: false,

        verificationCode: "",
        incorrectCode: false
    }

    sendUserCode = async () => {
        this.setState({isSendingUserCodeProcessing: true})

        let openDialog = false
        await fetch('https://cprforms.com/api/v1/' + this.props.username + '/resendcode', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "x-access-token": this.props.token

            },

        }).then(response => {

            if (response.ok) {
                // TODO: "An email has been sent to: this.props.userEmail
                openDialog = true
            }

            else {
                this.props.displaySnack({
                    openSnackbar: true,
                    snackbarText: "There appears to be a connection error. Please try again later.",
                    snackbarStyle: "#f14335"
                })
            }
        });

        this.setState({
            openConfirmationDialog: openDialog,
            isSendingUserCodeProcessing: false
        })
    }

    verifyUserCode = async () => {

        this.setState({isProcessingConfirmation:true});

        let snackbarText = "";
        let snackbarStyle = "";
        let code = this.state.verificationCode
        await fetch('https://cprforms.com/api/v1/confirm', {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                "username": this.props.username,
                "token": code.trim()
            })

        }).then(response => {

            if (response.ok) {
                snackbarText = "Your email has been confirmed, thank you"
                snackbarStyle = "#4caf50"

            } else if (response.status === 400) {
                this.setState({
                    incorrectCode: true,
                    isProcessingConfirmation: false,
                })

            } else {
                snackbarText = "There was a connection error, please try again later"
                snackbarStyle = "#f14335"
            }
        });


        // Do not use snackbar when the code is incorrect, the user will be told by the TextField
        if (this.state.incorrectCode){
            this.setState({isProcessingConfirmation: false});
            return
        }

        this.setState({
            isProcessingConfirmation: false,
            openConfirmationDialog: false,
            verificationCode: "",
            incorrectCode: false
        })

        this.props.displaySnack({
            openSnackbar: true,
            snackbarText: snackbarText,
            snackbarStyle: snackbarStyle
        })

        // Calls getUserProfile() in parent: Dashboard.js to update the userWebProfile which is used for flow of the ui
        await this.props.userWebProfile.updateCallback()
    }

    handleCloseConfirmationDialog = () => {
        this.setState({openConfirmationDialog: false})
    }

    render() {
        return (
            <div>
                <ListItem>
                    <ListItemText
                        primary={"Confirm email"}
                        secondary={"You must confirm your email before getting access to any features."}
                    />
                    <div style={{height: "100%", width: "100%"}}>
                        <ListItemSecondaryAction>
                            <Button color="primary" onClick={this.sendUserCode}>
                                Confirm
                                <PulseLoader
                                    css={overrideLoading}
                                    sizeUnit={"px"}
                                    size={this.state.isMobile ? 6 : 9}
                                    color={'#2180CE'}
                                    loading={this.state.isSendingUserCodeProcessing}
                                />
                            </Button>
                        </ListItemSecondaryAction>
                    </div>
                </ListItem>

                <Dialog
                    open={this.state.openConfirmationDialog}
                    onClose={this.handleCloseConfirmationDialog}
                    aria-labelledby="alert-dialog-cancel-sub"
                    aria-describedby="alert-dialog-cancel-sub-desc"
                >

                    <DialogTitle id="form-dialog-title">Confirm Email</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            A verification code has been sent to your email. Please enter it below and press "Confirm Code" at the bottom left of this screen.
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            id="code"
                            label="Verification Code"
                            onChange={evt => this.setState({verificationCode: evt.target.value})}
                            onFocus={() => this.setState({incorrectCode: false})}
                            fullWidth
                            error={this.state.incorrectCode}
                            helperText={this.state.incorrectCode ? "Invalid Code" : null}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseConfirmationDialog} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={this.verifyUserCode} color="primary">
                            Confirm Code
                            <PulseLoader
                                css={overrideLoading}
                                sizeUnit={"px"}
                                size={this.state.isMobile ? 6 : 9}
                                color={'#2180CE'}
                                loading={this.state.isProcessingConfirmation}
                            />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const overrideLoading = css`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 10px;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;


VerifyAccount.propTypes = {
    username: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    isMobile:  PropTypes.bool.isRequired,
    displaySnack: PropTypes.func.isRequired,
    userWebProfile: PropTypes.object.isRequired,
};

export default VerifyAccount;